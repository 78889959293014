
.loginwrapper {
  margin-top: 40px;
  margin-bottom: 80px;
}

.form-signin {
  max-width: 400px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-size: 16px !important;
}
.form-signin .form-signin-heading {
  margin-bottom: 30px;
}
.btn-block{
  width: 100% !important;
  display: block !important;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  padding: 7px !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin button[type="submit"] {
  height: 36px !important;
  font-size: 16px !important;
  padding: 0;
  outline: none;
}

/*-----------------------------------------------
	submit button
-----------------------------------------------*/
button[type="submit"], .outline-none {
  outline: none !important;
}

/*==================================================
 * shadow-1
 * ===============================================*/
.shadow-1 {
  -webkit-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  -moz-box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
}


/* author */
.author {
  float: left;
  font-family: "Maven Pro", sans-serif;
  font-size: 18px;
  margin: 15px 20px 30px;
}
