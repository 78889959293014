* {
  box-sizing: border-box;
}

.fellowsCoverPicContainer{
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-image: url('/fellowsBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 600px;
    position: relative;
}
.fellows-image{
    background-color:#172845;
    opacity:0.87;
    height:110px;
    position: absolute;
    bottom: 0;
}
.fellows-image h4{
    margin-top: 30px !important;
}
@media only screen and (max-width: 576px){
    .fellows-image h4{
        margin-top: 40px !important;
    }
}
.selectedYear{
    font-weight: bold;
    color: #002A59;
}
.background{
   
    margin: 0;
    padding:0;
    width: 100vw;
    height: 400px;
    background-color: #36455e;
  
    
}
.det h6{
    opacity: 0.67;
    font-family: "montserrat";
    margin-top: 5px;
    font-size: 14px;
}
.nav-bar{
    background-color: #172845;
    width: 100vw;
}
.nav-left{
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    font-family: Montserrat,sans-serif;
}
.det{
    margin-top: 25px;
    margin-bottom: 25px;

}
.det p{
    font-size: 16px;
    padding-right: 50px;
    margin-top:30px;
    font-family: "montserrat";
    line-height: 28px;
}
.det h4{

    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
  margin-bottom: 0px;
    
}
.det a{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #C1303E;
    text-decoration: none;
}
.det .fa {
    color: #C1303E;
    opacity: 1;
    margin-left: 5px;
    font-size: 12px;
}


.fellow-row{
    background-color: #f2f6f9;
    opacity: 1;
    height: auto;
    width: 100vw;
    margin: 0;
    padding: 0;
    
    
   
}
.fellows{
    height: auto;
    width: auto;
  margin-top: 15px;
  margin-bottom: 20px;
    margin-right: 350px;
    background-color: #FFFFFF;
 
 display: flex;
 flex-direction: row;
}
.colm-right{
  
   margin-top: 60px;
  margin-bottom: 60px;

}
.foo{
    height: 5px;
}
.foo img{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-left: 250px;

}
.fellows-year{
   padding-top: 80px;
  
}
.fellows-year h4{
    padding-left: 130px;
    font-size: 16px;
    font-family: "montserrat";
}
.fellows-year p{
    padding-left: 130px;
    margin-bottom: 5px;
    font-family: "montserrat";
}
.fellows-year hr{
    width: 120px;
    margin-top: 0px;
    margin-left: 130px;
}
.fellows img{
 margin-left: 30px;
 margin-top: 20px;
 border-radius: 50%;
 margin-right: 30px;
border: 1px solid silver;
  
  
}
.nation-challenge{
    background-color: #c1303e;
   
    width: 100vw;
    height: auto;
    margin: 0;
    padding: 0;
    padding-top: 30px;
    padding-bottom: 30px;
}
.nations-text{
    color: #FFFFFF;
    opacity: 1;
    height: 100px;
}
.nations-text h4{
   justify-content: center;
}

.last-part{
    background-color: #f2f6f9;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: auto;
}
.nav-right{
    line-height: 0.5;
}

.grid{
  
    height: 100vh;
    width: 100vw;
    display: grid;
    padding: 10px;
    grid-template-rows: 90px 380px 1150px 400px 300px 300px 70px ;
    margin: 0;
    padding: 0;
   overflow-x: hidden;

}
.footer img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}


.class6{

  
   
    background-color: #f2f6f9;
    
    width: 100%;
    height: 300px;
}
.col1{
    height: 150px;
    width: 200px;
  
    margin-top: 100px;
   
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.col1 h2{
    color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col1 a{
  
    margin-bottom: 10px;
    color:#707070;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col1 a:hover{
    color: silver;
}
.col2{
   height: 150px;
    width: 380px;
   
     margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    color: #172845;
    display: flex;
    flex-direction: column;
   
    font-family: Montserrat,sans-serif;
}
.col2 h2{
 margin-top: 8px;
    color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col2 a{
    color: #707070;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
     text-decoration: underline;
}
.col2 a:hover{
    color: silver;
}
.col3{
    height: 150px;
    width: 300px;
    
     margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
}
.col3 h2{
    margin-top: 8px;
    color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col3 input{
   padding: 10px;
   padding-right: 120px;
   background-color: #f2f6f9;
   border: 1px solid grey;
}
.col4 h2{
    margin-top: 8px;
        color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.buttonxo {
  background-color: #C1303E;
  border: none;
  color: white;
  padding: 5px;
 margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-family: Montserrat,sans-serif;
 outline: none;
  cursor: pointer;
}
.button2121{
    border-radius: 12px;
    
}
.button2121:hover{
    background-color: red;
}
.button2121 {
  background-color: #C1303E; 
  color: #F2F6F9; 
  border: 2px solid #F2F6F9;
}
.col4{
   height: 150px;
    width: 300px;
  
     margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;;
}
.col4 h2{
    font-size: 20px;
    font-family: Montserrat,sans-serif;
    color: #172845;
}
.col4 img{
    height: 100px;
    width: 200px;
    border-radius: 8px;
    padding-top: 0;
    margin-top: 0;
}



.background h4{
    color: white;
    opacity: 0.87;
    margin-top: 150px;
    margin-left: 190px;

}

@media only screen and (max-width: 576px) {
    .guidelines-image{
   
        
        margin-top: 0px;
       
    }
    .micro-container{
      
        background-image: url('/fellowship_image_mobile.png')!important;
    }
.fellows-year h4{
    padding-left: 0px;
}
.fellows-year p{
    padding-left: 0px;
}
.fellows-year hr{
    width: 330px;
    margin-left: 0px;
}
.colm-right{
    margin-top: 0px;
    margin-bottom: 30px;
}
.fellows{
    margin-right: 0px;
}
.det p{
    padding-right: 15px;
}
}
@media (min-width: 320px) and (max-width: 767px)  {
    .fellows {
        display: flex;
        flex-direction: column;
    }
    .det h4 {
        padding-left: 10%;
    }
    .det h6 {
        padding-left: 10%;
    }
    .det p {
        padding-left: 10%;
    }
    .det a {
        padding-left: 10%;
    }
    .fellows-year {
        padding-left: 10%;
    }
    .colm-right {
        padding: 0% 10%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .fellows-year h4 {
        padding-left: 10%;
    }
    .fellows-year p {
        padding-left: 10%;
    }
    .fellows-year hr {
        margin-left: 10%;
    }
    .fellows {
        margin-right: 5%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .fellows-year h4 {
        padding-left: 10%;
    }
    .fellows-year p {
        padding-left: 10%;
    }
    .fellows-year hr {
        margin-left: 10%;
    }
    .fellows {
        margin-right: 5%;
    }
}
