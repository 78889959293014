
.blog-author {
  background: #ffffff;
  padding: 2% 7.5%;
  box-shadow: 0px 0px 29px rgba(23, 40, 69, 0.13);
}
.author-detail {
  display: flex;
  /* margin-bottom: 30px; */
}
.author-by {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
  margin-bottom: 5px;
}
.author-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #35455e;
  margin-bottom: 10px;
}
.author-description {
  padding: 1% 0%;
}
.blog-author-info {
  padding-top: 15px;
}
.blog-author-date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
}
.blog-author-date p{
  margin-bottom: 10px;
}
.blog-author-img img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin-right: 15px;
  margin-top: 15px;
}
.author-quote {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #35455e;
  margin-left: 55px;
  margin-top: -15px;
}
.blog-content {
  background-color: #f2f6f9;
  padding: 5% 25% 0% 25%;
  text-align: justify;
}
.blog-recent p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #35455E;
  margin-bottom: 30px;
}
.blog-recent-article {
  display: flex;
  margin-bottom: 25px;
}
.blog-recent-article img {
  border-radius: 6px;
  width: 150px !important;
  height: 150px !important;
  margin-right: 15px;
  object-fit: cover;
  
}
.blog-recent-article-content {
  margin-right: 30px;
}
.blog-recent-article-content p {
 width: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #35455E;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: initial;
}
.blog-recent-article-content a {
 font-family: "montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #C1303E !important;
  cursor: pointer;
  letter-spacing: 0.06em;
}
.blog-recent-article-content .fa {
  color: #C1303E;
  opacity: 1;
  margin-left: 5px;
  font-size: 12px;
}
.blog-recent-article-detail span{
  font-size: 18px !important;
  line-height: 30px;
  color: #676767;
}
.blog-recent-article-detail h1{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #35455E;
  margin-bottom: 30px;
}
.blog-recent-article-detail p {
  font-family: "Montserrat";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 28px;
color: #676767;
margin-bottom: 25px;
}
#intro-image{
width: 100% ;
height: 500px  ;
object-fit: cover;
margin-top: 25px;
}
/* .blog-recent-article-detail img {
width: 100% !important;
height: 200px !important;
object-fit: cover;
margin-top: 25px;

} */
.blog-recent-article-detail-image-2nd img{
width: 100% !important;
height: 200px !important;
object-fit: cover;
margin-top: 25px;

}
.blog-images{
  width: 33%;
}
.modal-body {
  overflow: initial;
}
.modal-body img{
  width: 100%;
  height: calc(100vh - 300px);
  object-fit: contain;
}
.modal-body p{
  margin-top: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  font-style: italic;
}
.modal-content {
 

  background-color: transparent;

  border: none;

}
.video-background {
  /* background-image: url("/videobanner.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 400px; */
  background-color: #333;

}
video{
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: inherit;
}
.video-background svg {
  position: relative;
top: 50%;
left: 50%;
transform: translate(-50%,-50%);
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 25% 0% 25%;
  text-align: justify;
}
.blog-recent-article-detail-image-2nd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.blog-recent-article-detail-image-2nd img{

  border-radius: 6px;
  margin-right: 30px;
}
.design-quote{
  margin-bottom: 20px;
}
.blog-recent-article-detail .design-quote .author-quote{
  font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
   margin-left: 55px;
   color: #35455e;
}
/* .author-svg{
  margin-left: 30px;
} */
.design-quote-wrapper {
  padding: 5px 0px;
}
.recent-article {
  background-color: #ffffff;
  padding: 3% 7% 3% 7%;
}
.recent-article h3 {
  color: #35455E;
  font-family: "montserrat";
}
.photo-credit {
  font-size: 14px !important;
  margin-bottom: 50px !important;
  margin-top: 10px;
  font-style: italic !important;
}
@media (min-width: 220px) and (max-width: 319px) {
  .blog-content {
    background-color: #f2f6f9;
    padding: 10% 5% 0% 5%;
    text-align: justify;
  }
  .blog-content-2nd {
    background-color: #f2f6f9;
    padding: 0.5% 5% 0% 5%;
    text-align: justify;
  }
  .recent-article {
    background-color: #f2f6f9;
    padding: 0% 5% 5% 5%;
    text-align: justify;
  }
  .blog-recent-article-detail-image-2nd {
    display: inline-block;
    
}
.blog-images{
  width: auto;
}
}
@media (min-width: 320px) and (max-width: 767px) {
  .blog-author-info {
    padding: 10px 0px;

  }
  .author-description {
    padding: 0px 0px;
   
}

.author-svg svg {
  width: 25px;
}
.author-quote {
  font-size: 16px;
  line-height: 30px;
  margin-left: 40px;
}
.video-background svg  {
  width: 50px;
}
.blog-recent-article-detail {
  margin-top: 25px;
}
.blog-recent-article-detail img {
    width: 100% !important;
    height: 195px !important;
    
}
.blog-recent-article-detail-image-2nd img {
      margin-top: 30px;
  }
  .blog-recent-article-detail p {
    font-size: 14px;
}

.author-detail {
  padding-left: 10px;
}
  /* .design-quote {
    height: 150px;
} */
.design-quote-wrapper {
  padding: 0px 0px;
}
  .blog-recent-article-detail .design-quote .author-quote {
    line-height: 24px;
    font-size: 14px;
    margin-left: 35px;

}
.blog-content {
  background-color: #f2f6f9;
  padding: 10% 5% 0% 5%;
  text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 5% 0% 5%;
  text-align: justify;
}
.recent-article {
  background-color: #ffffff;
  padding: 8% 5% 5% 5%;
  text-align: justify;
}
.blog-recent-article-detail-image-2nd {
  display: inline-block;
  
}
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog-content {
    background-color: #f2f6f9;
    padding: 5% 10% 0% 10%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 10% 0% 10%;
  text-align: justify;
}
.recent-article {
  background-color: #f2f6f9;
  padding: 5% 7% 5% 7%;
  text-align: justify;
}
.blog-recent-article img {
  width: 100px !important;
  height: 100px !important;
}
.blog-recent-article-content p {
  width: 150px;
  font-size: 14px;
}
.blog-recent-article-content a {
  font-size: 12px;
}
  .author-quote {
    font-size: 18px;
    line-height: 32px;
    
  }
  .blog-recent-article-detail img {
    width: 100% !important;
    height: 190px !important ;
}
.blog-recent-article-detail .design-quote .author-quote {
  font-size: 16px;
  line-height: 30px;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-content {
    background-color: #f2f6f9;
    padding: 5% 10% 0% 10%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 10% 0% 10%;
  text-align: justify;
}
.recent-article {
  background-color: #ffffff;
  padding: 5% 7% 5% 7%;
  text-align: justify;
}
  .blog-recent-article-detail img {
    width: 100% !important;
}
/* .design-quote {
  height: 180px;
} */
.blog-recent-article img {
  width: 90px !important;
  height: 90px !important;

}
}
@media (max-width: 320px) {
  .blog-author-info {
    padding: 10px 0px;

  }

.author-svg {
  margin-left: 10px;
}
.author-svg svg {
  width: 25px;
}
.author-quote {
  font-size: 16px;
  line-height: 30px;
  margin-left: 40px;
}
.blog-recent-article-detail {
  margin-top: 25px;
}

.blog-recent-article-detail .blog-recent-article-detail-image img {
      width: 190px !important;
      height: auto !important;
  }
  /* .design-quote {
    height: 220px;
} */
.design-quote-wrapper {
  padding: 0px 0px;
}
  .blog-recent-article-detail .design-quote .author-quote {
    line-height: 24px;
    font-size: 14px;
    margin-left: 42px;

}
 .blog-recent-article-detail img {
  width: 100% !important;
  
}
.video-background {
  height: 160px;
}
}
@media (min-width: 1200px) and (max-width: 1419px){
  .blog-content {
    background-color: #f2f6f9;
    padding: 5% 13% 0% 13%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 13% 0% 13%;
  text-align: justify;
}
.recent-article {
  background-color: #ffffff;
  padding: 5% 8% 5% 8%;
  text-align: justify;
}
.blog-recent-article img {
  width: 100px !important;
  height: 100px !important;
}
}

@media (min-width: 1420px) and (max-width: 1519px){
  .blog-content {
    background-color: #f2f6f9;
    padding: 10% 15% 0% 15%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 15% 0% 15%;
  text-align: justify;
}
.recent-article {
  background-color: #f2f6f9;
  padding: 0% 15% 5% 15%;
  text-align: justify;
}
}