
#guide-active {
  color: silver;
}
#faq-active {
  color: silver;
}
#fellow-active {
  color: silver;
}
#blog-active {
  color: silver;
}
.mun-name{
  word-break: break-word;
}
.hilight-text p {
  background-color: #e5eaee;
  padding-left: 250px;
  padding-right: 150px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.micro-container-guidelines {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-image: url("/MicrosoftTeams-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.guidelines-image {
  height: 170px;
  width: 100%;
  margin-top: 100px;
  opacity: 0.87;
}
.qns {
  background-color: #f2f6f9;
}
.qns p {
  margin-left: 170px;
  margin-right: 280px;
}
.qns ol {
  margin-left: 15%;
  margin-right: 280px;
  line-height: 2;
  font-family: "montserrat";
  font-size: 14px;
}

.test {
  background-color: #e5eaee;
  padding-top: 20px;
  padding-left: 30px;
}
.test p {
  margin-right: 280px;
}

.selection {
  background-color: #e5eaee;
  padding-left: 30px;
}
.selection h4 {
  margin-left: 112px;
}
.selection p {
  margin-left: 175px;
  font-weight: bold;
}
.blog-soon {
  height: 300px;
  padding-left: 15px;
}
.blog-soon p {
  color: #172845;
  opacity: 0.87;
  font-size: 25px;
  font-family: Montserrat, sans-serif;
  font-weight: lighter;
  margin-left: 140px;
  margin-top: 80px;
}

.btn {
  height: 50px;
  width: auto;
}

.col h4 {
  padding-right: 480px;
  margin-left: auto;
}

#online-form {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
#online-form:hover {
  color: black;
  text-decoration: underline;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: auto;
  margin-left: 15% !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: "montserrat";
}

/* added css */
.guidelines-topics {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) and (max-width: 991px) {
  .qns h4{
    margin-left: 7% !important;
}
.qns p{
  margin-left: 7% !important;
  margin-right: 10%;
}
.guidelines-topics {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  margin-right: 10px;
}
.quesn-catagory1 h4 {

border-right: none !important;

}

.test1 {
padding-right: 30px;
padding-top: 50px;
}
.qns {

padding-bottom: 50px !important;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qns h4{
    margin-left: 15.5% !important;
}
.qns p{
  margin-left: 15.5% !important;
  margin-right: 10%;
}
.quesn-catagory1 h4 {
 
  border-right: none !important;
  
}
}
@media (min-width: 320px) and (max-width: 767px) {
  table {
    
    margin-left: 0% !important;
}
  .guidelines-topics {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
}
.quesn-catagory1 h4 {
 
  border-right: none !important;
  
}
td,
th {
  font-size: 12px;
}
.test1 {
  padding-right: 30px;
  padding-top: 50px;
}
.qns {
  
  padding-bottom: 50px !important;
}
}
@media (min-width: 1200px) and (max-width: 1419px) {
  .quesn-catagory1 h4 {
 
    border-right: none !important;
    
  }
}