/* blog listing */
.micro-container-guideliness {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-image: url(/MicrosoftTeams-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.blog-box {
  margin-top: 10%;
}
.blog-row {
    margin-bottom: 30px;
    padding: 0% 7%;
  }
  .blog-list {
  padding: 40px 0px;
  background-color: #F2F6F9;
  }
  .blog-box img {
  width: 340px ;
  height: 259px ;
  border-radius: 3px;
  object-fit: cover;
  }
  .blog-tag {
    margin-top: 10px;
  }
  .blog-tag p{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #676767;
    margin-bottom: 0px;
  }
  /* .blog-list-content{
    height: 158px;
  } */
  .blog-list-content h1{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #35455E;
  margin-top: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 330px;
  }
  .blog-list-content p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #676767;
  margin-top: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 330px;
  }
   a {
    text-decoration: none;
  }
  .blog-box button {
    background: #fff;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #35455E;
    border-color: #C1303E;
  }
  .blog-box   .fa{
    color: #C1303E;
    opacity: 1;
    margin-left: 5px;
    font-size: 12px;
  }
  .blog-box button a{
    color: #35455E;
    background-color: transparent;
  }
   a:hover{
    color: #C1303E;
    text-decoration: none;
   
  } 
  /* .blog-box .btn:hover {
      color: #fff;
      background-color: #C1303E;
      border-color: #C1303E;
  } */
  /* .blog-box button  .fa:hover{
    color: #fff;
    opacity: 1;
  } */
  .readmore-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #C1303E;
    text-decoration: none;
    font-family: "montserrat";
  }
  .readmore-button a {
    text-decoration: none;
  }
  @media (min-width: 320px) and (max-width: 767px)  {
    /* .blog-box img {
      width: 330px !important;
  } */
  .blog-recent-article-content p {
    text-align: initial;
  }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .blog-box img {
      width: 200px;
      height: 180px;
    }
    .blog-list-content h1 {
      width: 200px;
    }
    .blog-list-content p {
      width: 200px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .blog-recent-article-content p {
      text-align: initial;
    }
    .blog-box img {
      width: 265px;
    }
    .blog-list-content h1 {
      width: 265px;
    }
    .blog-list-content p {
      width: 265px;
    }

  }