.logo-link {
    display: flex;
}
.logo-link img {
    width: 80px;
    height: 80px;
    margin-left: 0px;
    object-fit: contain;
}
.nav-wrapper {
    padding: 0% 7%;
}
#nav-left {
    margin-left: 0;
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
    line-height: 1;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    margin-top: 20px;
}
#responsive-navbar-nav {
    justify-content: flex-end;
}
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-toggler {
    color: #fff;
    border: none;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-nav {
    font-family: "montserrat";
}
/* media queries */
@media (min-width: 321px) and (max-width: 767px) {
    .logo-link img {
        width: 55px;
        height: 55px;
        margin-left: 0px;
        object-fit: contain;
    }
    #nav-left {
        margin-top: 10px;
        padding-left: 0px;
        font-size: 12px !important
    }
}
@media (max-width: 320px) {
    .logo-link img {
        width: 60px;
        height: 60px;
        margin-left: 0px;
        object-fit: contain;

    }
    #nav-left {
        margin-top: 10px;
        padding-left: 0px;
        font-size: 8px !important;
    }
}