* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}
.content {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.linking-bipad {
  text-decoration: underline;
  cursor: pointer;
}
.linking-bipad:hover {
  color: blue;
}
.Myrow2 p {
  padding-left: 17.7% !important;
  padding-right: 370px !important;
  font-family: "montserrat";
  line-height: 28px;
}
@media only screen and (max-width: 576px) { 
  * {
    box-sizing: border-box !important;
  }
  .Myrow2 p {
    padding-left: 30px !important;
    padding-right: 30px !important;
    line-height: 1.5;
  }
  .H4 h4 {
    margin-top: 0px !important;
    margin-left: 50px !important;
  }
  .blog-images{
    width: 100% !important;
  }
  .blog-box img{
    width: 100% !important;
    object-fit: contain;
  }
  #intro-image{
    /* width: 100% !important;
    height: 200px !important; */
    object-fit: contain !important;
    margin-top: 0px !important;

  }
  .H4 h6 {
    margin-left: 10px !important;
    margin-bottom: 20px !important;
  }
  /* .col-sm-8 h4{
    margin-left: 0px !important;
  } */

  .inside-content h4 {
    padding-left: 10px !important;
    margin-top: 20px;
    font-size: 16px;
    margin-right: 30px;
  }
  .inside-content p {
    padding-left: 0px !important;
    padding-right: 20px;
  }
.pic1{
  width: 100% !important;
  padding-bottom: 20px;
  margin-left: 30px;
}
.pic2{
  width: 100% !important;
  padding-bottom: 20px;
  margin-left: 30px;
}
.pic3{
  width: 100% !important;
  padding-bottom: 50px;
  margin-left: 30px;
}
  .pic1 img {
    width: 100% !important;

  }
  .pic2 img {
    width: 100% !important;
 
   
  }
  .pic3 img {
    width: 100% !important;
  
  }
  .Myrow3{
    padding-left: 20px !important;
    padding-right: 20px;
  }
  .Myrow3 button{
    margin-left: 10px !important;
  }
  .Myrow1 button {
    margin-left: 10px !important;
  }

  .row2-landing button {
    margin-left: 20px !important;
  }
  .youth-lab img {
    height: 50px !important;
    width: 100px !important;
  }
  .Myrow5 img {
    margin-left: 0px !important;

    margin-right: 0 !important;
    height: 25px !important;
    width: 25px !important;
  }
  .col-sm-3 button {
    margin-left: 60px;
  }
  .row2-landing h4 {
    padding-left: 20px !important;
    font-size: 20px !important;
  }
  .row2-landing p {
    padding-left: 20px !important;
    font-size: 16px !important;
    padding-right: 30px !important;
  }
  .ray h4 {
    margin-left: 30px !important;
    margin-top: 70px !important;
    font-size: 20px !important;
    margin-top: 60px !important;
  }

  .blog-soon p {
    margin-left: 20px !important;
    margin-top: 140px !important;
    margin-right: 20px !important;
  }
  .quesn-catagory1 {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
  }
  /* .quesn-catagory1 h4{
    display: none;
  } */
  .vl{
    display: none;
  }
  .quesn-catagory {
    padding-left: 0px !important;
  }
  .quesn-catagory1 button{
    padding-left: 40px;
    text-align: left;
  }
  .ray-fellows h4 {
    margin-left: 30px !important;
    margin-top: 70px !important;
    font-size: 20px !important;
  }

  .quesn-catagory h4 {
    margin-left: 40px !important;
  }

  .micro-container-guidelines {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-image: url("/MicrosoftTeams-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  /* #overview1 {
    margin-left: 40px !important;
    margin-right: 80px !important;
    padding-left: 0px !important;
  } */
  /* #online-application {
    margin-left: 110px !important;
  }
  #ref-guidelines {
    margin-left: 110px !important;
  } */
  /* #download {
    margin-left: 115px !important;
  } */
  .qns h4 {
    margin-left: 5px !important;
    margin-right: 20px !important;
    font-size: 18px !important;
  }
  .qns p {
    margin-left: 5px !important;
    margin-right: 30px !important;
  }
  .qns ol {
    margin-left: 5px !important;
    margin-right: 30px !important;
  }

  .ray {
    height: auto !important;
  }
  .ray1 {
    height: 190px !important;
  }
  .ray1 h4 {
    margin-left: 10px !important;
    margin-top: 50px !important;
  }
  .ray-fq {
    height: auto !important;
  }
  .DRR h4 {
    margin-left: 20px !important;
  }

  .mail h4 {
    padding-left: 5px !important;
  }
  #buttom-menu {
    padding-left: 5px !important;
  }
  .signup h4 {
    padding-left: 5px !important;
  }
  .signup input {
    margin-left: 5px !important;
  }
  .col-sm-3 button {
    margin-left: 5px !important;
  }
  .youth-lab h4 {
    padding-left: 10px !important;
  }
  .youth-lab img {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .support h4 {
    margin-left: 10px !important;
  }
  .support img {
    margin-left: 0 !important;
    height: 50px !important;
    width: 100px !important;
  }
  .ray-fq h4 {
    margin-top: 70px !important;

    padding-left: 40px !important;
    font-size: 20px !important;
  }
  .Myrow5 h4 {
    padding-left: 10px !important;
    font-size: 8px !important;
    margin-top: 12px !important;
  }
  
  .pictures {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }
  .container{
    padding-left: 30px;
  }
  .ray-fellows {
    height: 190px !important;
  }
  .btn {
    height: 30px !important;
    width: auto !important;
  }
  .quesn-catagory a {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .quesn-catagory1 h4 {
    margin-left: 30px !important;
  }

  /* #elig {
    margin-left: 110px !important;
  } */
  /* #key-res {
    margin-left: 110px !important;
  } */
  .quesn-catagory1 a {
    margin-left: 10px !important;
    padding-left: 20px !important;
  }
  /* #applicationsum {
    margin-left: 110px !important;
  }
  #application-process {
    margin-left: 110px !important;
  } */
  .Myrow4 {
    height: auto;
    width: auto;

    padding-top: 80px;
    padding-bottom: 40px;
    background-color: #f2f6f9;
    padding-left: 10px !important;
    padding-right: 130px;
  }
  #financial-info {
    /* margin-left: 110px !important; */
    margin-bottom: 20px;
  }
  .paragraph-ans {
    font-size: 15px !important;
    color: black !important;
  }
  /* .fa {
    color: black !important;
    font-size: 15px !important;
    height: 25px !important;
  } */
  .micro-container {
    padding-left: 0px !important;
  }
  .Myrow1 h4 {
    padding-left: 10px !important;
    /* margin-top: 20px !important; */
    font-size: 16px !important;
  }
  .Myrow3 h4 {
   
    font-size: 18px !important;
    margin-right: 20px !important;
  }
  #link-pics {
    margin-left: 0 !important;
  }
  .frequent h4 {
    font-size: 18px !important;
    margin-left: 20px !important;
  }
  .questions-answers {
    width: 350px !important;
    margin-left: 12px !important;
  }
  .paragraph-qus {
    font-size: 15px !important;
    padding-right: 70px !important;
  }
  .navbar img {
    margin-left: 0 !important;
  }
  .right {
    margin-left: 10px !important;
  }
  /* #nav-left {
    margin-left: 10px !important;
    font-size: 12px !important;
  } */
  .tab {
    margin-left: 0px !important;
  }

  .IMG img {
    margin-left: 80px !important;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    left: 0% !important;
  }
}

.inside-content {
  padding-left: 70px !important;
}

.navbar {
  width: auto;
}
.navbar-brand {
  margin-top: 20px;
  margin-right: 0;
}
.Myrow1 {
  padding: 0;
  margin: 0;
  width: auto;
  padding-left: 15px;
  background-color: #36455e;
}
.ray {
  padding: 0;
  margin: 0;
  height: 400px;
  width: auto;
  background-color: #36455e;
}
.ray-fellows {
  padding: 0;
  margin: 0;
  height: 400px;
  width: auto;
  background-color: #36455e;
}

.row button:focus {
  outline: none;
}

.Myrow3 {
  height: auto;
  width: auto;
  background-color: #c1303e;
  padding-left: 130px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.Myrow3 h4 {
  /* padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 140px;
  padding-right: 10px; */
  color: white;
  font-size: 24px;
  font-family: Montserrat, sans-serif;
  opacity: 1;
  font-weight: bold;
}
.bot {
  margin-top: 50px;
  height: 50px;
  width: 200px;
  color: #c1303e;
}
.bot1 {
  margin-top: 50px;
  height: 50px;
  width: 150px;
  color: #c1303e;
  border-radius: 8px;
}
.bot1 p {
  font-size: 15px;
  padding-left: 5px;
  margin: 5px;
  font-family: Montserrat, sans-serif;
  color: white;
}

.bot p {
  font-size: 15px;
  padding-left: 5px;
  margin: 5px;
  font-family: Montserrat, sans-serif;
  color: #ffffff;
  opacity: 0.87;
}
.Myrow4 {
  height: auto;
  width: auto;

  padding-top: 80px;
  padding-bottom: 40px;
  background-color: #f2f6f9;
  padding-left: 110px;
  padding-right: 110px;
}
/* .col-sm-3{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 0px;
   
    
} */

.support img {
  height: 100px;
  width: 240px;
}

.Myrow5 {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  background-color: #172845;
  opacity: 0.86;
}
.Myrow5 img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.logo img {
  margin-left: 150px;
}

.col-sm-6 {
  margin-top: 15px;
  margin-bottom: 10px;
}
.col-sm-4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.col-sm-6 h4 {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #707070;
}
.navbar-brand img {
  margin-left: 50px;
}
#nav-left {
  margin-left: 0;
  color: #ffffff;
  opacity: 1;
  font-size: 14px;
  line-height: 1;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}
.right {
  margin-right: 115px;

  opacity: 1;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  margin-left: 170px;
}
#nav-right {
  color: #ffffff;
}
.H4 h4 {
  font-size: 30px !important;
  color: #ffffff;
  opacity: 0.87;
  font-family: Montserrat, sans-serif !important;
  font-weight: bold;

  margin-top: 120px;

  margin-left: 0px;
}
.H4 h6 {
  font-size: 16px !important;
  color: #ffffff;
  opacity: 0.87;
  font-family: Montserrat, sans-serif !important;
  margin-left: 0px;
  margin-bottom: 100px;
}
.IMG img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* margin-top: 70px;
  margin-left: 270px;
  margin-bottom: 30px; */
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(0%,-50%);
}

#fellow-name h4 {
  margin-top: 80px;
  margin-left: 0px;
  padding-left: 0px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .Myrow2 p{
    padding: 0% 10% !important;
    line-height: 28px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .IMG img {

    margin-left: 15%;
    left: 0% !important;
 
}
#fellow-name h4 {
  margin-top: 18%;

}
.Myrow2 p {
  padding-left: 10% !important;
  padding-right: 8% !important;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .IMG img {
    left: 0% !important;
    margin-left: 19%;
 
}
#fellow-name h4 {
  margin-top: 15%;

}
.Myrow2 p {
  padding-left: 9% !important;
  padding-right: 8% !important;
}
}