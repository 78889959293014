.ray h4{
    color: #FFFFFF;
    font-size: 55px;
    font-family: Montserrat,sans-serif;
    margin-top: 50px;
    margin-left: 7%;  
}
.ray1 h4{
    color: #FFFFFF;
    opacity: 0.87;
    font-size: 55px;
    font-family: Montserrat,sans-serif;
    margin-top: 100px;
    margin-left: 125px;  
    
}
.ray1{
    background-color:  #36455e;
    height: 400px;
    padding-left: 15px;
}
.ray-fq {
     color: #FFFFFF;
   
    font-size: 55px;
    font-family: Montserrat,sans-serif;
    background-color:  #36455e;
      
    height: 400px;
}
.ray-fq h4 {
    color: #FFFFFF;
    font-size: 55px;
    font-family: Montserrat,sans-serif;
   
    padding-left: 7%;
    padding-right: 20px;
    margin-bottom: 100px;
    margin-top: 20px;
}
.ray-fellows h4{
    color: #FFFFFF;
    font-size: 40px;
    font-family: Montserrat,sans-serif;
    margin-top: 50px;
    margin-left: 7%;  
    }

.Myrow2{
    height: auto;
    background-color: #f2f6f9;
    padding-top: 40px;
    padding-bottom: 40px;
}
.Myrow2 h4{
    margin-left: 20px;
    margin-bottom: 30px;
    color: #172845;
    opacity: 0.87;
    font-size: 30px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
}

.DRR{
    margin-bottom: 20px;
}


.DRR h4{
margin-left: 24px;
font-size: 14px;

font-family: Montserrat,sans-serif;
}

.mail{
    margin-left: 0px;
    
}

.mail h4{
    font-size: 14px;
font-family: Montserrat,sans-serif;
padding-left: 10px;

}
.signup h4{
   font-size: 14px;

padding-left: 110px;
font-family: Montserrat,sans-serif;

}
.signup{
    margin-bottom: 20px;
}
.signup input{
    height: 30px;
    font-size: 12px;
    padding-right: 40px;
    margin-left: 110px;
    margin-bottom:5px;
}
.support h4{
       font-size: 14px;

font-family: Montserrat,sans-serif;
}
.questions-answers{
   height: auto;
   margin:20px;
   margin-left: 10.5%;
   width: 900px;
   background-color: white;
   
}
.paragraph-ans{
   margin-right: 120px;
   padding-bottom: 10px;
   font-size: 18px;
   line-height: 1.6 !important;
   color: #000000DE;
   opacity: 0.87 !important;
   font-family: Montserrat,sans-serif;
   margin-left: 30px;
   
}
.paragraph-qus{
    margin-left: 35px;
    color: #172845;
    opacity: 0.87;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat,sans-serif;
    margin-top: 10px;
}
.application-process{
    margin-top: 20px;
   
 
}

.fa{
color: #35455E;
font-size: 25px;
}

.ques1 button{
    border: none;
    background-color: white;
    margin-left: auto;
    margin-right: 30px;
}
.ques1{
    display: flex;
}
.quesn-catagory{
    height: auto;
    background-color: white;
    display: flex;
  
}
.quesn-catagory a{
    text-decoration: none;
}
.quesn-catagory1{
    height: auto;
    display: flex;
    padding: 0% 6.9%;
    background-color: white;
}
.quesn-catagory1 a{
    text-decoration: none;
}
.quesn-catagory{
    padding-left: 25px;
}
.quesn-catagory a{
margin-left: 18px;
margin-top: 20px;
font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
margin-bottom: 15px;
}

.quesn-catagory1 a{
margin-left: 25px;
margin-top: 20px;
margin-bottom: 20px;
font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;


}
.quesn-catagory a:hover{
text-decoration: none;
}

.quesn-catagory1 a:hover{
text-decoration: none;
}

.quesn-catagory button{
    font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
margin-top: 5px;
}


.quesn-catagory1 button{
    font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
margin-top: 10px;
}

.quesn-catagory img{
    height: 20px;
    width: 20px;
    margin-left: 5px;
}

.quesn-catagory1 img{
    height: 20px;
    width: 20px;
    margin-left: 5px;
}


.quesn-catagory h4{
    margin-left: 104px;
    margin-top: 20px;
    padding-right: 20px;
    color: #35455E;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    font-weight: normal;
}

.quesn-catagory1 h4{
    margin-top: 20px;
    padding-right: 10px;
    color: #35455E;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    font-weight: normal;
    border-right: 2px solid #C1303E;
    height: 25px;
}

.fa{
        color: #35455E;
    font-size: 15px;
    opacity: 0.67;
}




.vl {
  border-left: 1px solid #36455e;
  height: 30px;
  padding-top: 10px;
  font-weight: normal;
  margin-top: 11px;
  
}


.qns{
    width: auto;
    padding-bottom: 80px;
}

.qns h4{
    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
    margin-left: 15%;
    margin-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.qns p{
    margin-left: 15%;
    color: #000000DE;
    opacity: 1;
    line-height: 28px;
    font-size: 16px;
    font-family: "montserrat";
   
}
.frequent p{
    font-family: "montserrat";
    color: #000000DE;
    opacity: 1;
    line-height: 1.5;
    font-size: 16px;  
    
}
.frequent{
    width: auto;
    padding-bottom: 30px;
    background-color: #f2f6f9;
}

.frequent h4{
    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
    margin-left: 10.5%;
    margin-bottom: 50px;
    margin-top: 50px;
}

#buttom-menu{
    padding-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}
#buttom-menu:hover{
color: blue;
text-decoration: underline;
}

.mail{
    margin-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 767px) {

}
@media (min-width: 768px) and (max-width: 991px) {
    .questions-answers {
        width: auto;
    }
    .quesn-catagory h4 {
        margin-left: 25px;
    }
}
@media (min-width: 992px) and (max-width: 1199px)  {
    .questions-answers {
        width: auto;
    }
    .quesn-catagory h4 {
        margin-left: 45px;
    }
}


.collapsible {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}