.query-form{
    border: 1px solid black;
}

.query-data{
    padding-left: 0px !important ;
}

.sidebar{
    padding-right: 0px !important;
}