.Myrow1 h4{
    color: #FFFFFF;
    opacity: 1;
    font-size: 22px;
    font-family: Montserrat,sans-serif;
    margin-top: 450px;
    /* margin-top: 20px; */
   padding-left: 145px;
   padding-right: 20px;
   padding-top: 30px;

    
}
.landing-row{
    padding-left: 0px !important;
}
.micro-container{
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-image: url('/fellowship_image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 600px;

  
  
}
.landing-image{
    background-color: #172845;
    opacity: 0.87;
    height: 180px;
}
/* .landing-image{
    margin-top: 30px;
    background-color: #172845;
    opacity: 0.67;
} */
/* .landing-image{
    position: absolute;
   bottom: 0%;
    width: 100%;
    background-color: grey;
    opacity: 0.67;
} */
.navbar{
   
    background-color: #172845 !important;
   
}

#buttom-menu:hover{
    color: silver;
    text-decoration: none;
}
#nav-right:hover{
    color: grey;
}
#nav-left:hover{
    color: grey;
}
.Myrow1 button{
    margin-left: 145px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "montserrat";
    background-color: #C1303E;
    border-radius: 3px;
    border:1px solid #C1303E;
    height: 35px;
    width: 100px;
    font-weight: 600;
    font-size: 12px;

}

.Myrow1 button:hover{
    background-color: red;

}

.row2-landing{
    background-color: #f2f6f9;
    height: auto;
    padding-left: 16px;
    padding-top: 50px;
}
.row2-landing button:hover{
    background-color: red;
   
}
.bot-learn p:hover{
    color: white;
}
.row2-landing h4{
    margin-top: 30px;
   padding-left: 140px;
   padding-right: 20px;
    margin-bottom: 30px;
    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
}
.row2-landing p{
    color: #000000DE;
    opacity: 1;
    line-height: 28px;
    font-size: 16px;
    padding-left: 140px;
    padding-right:350px;
    font-family: "Montserrat";
}

.row2-landing button{
    margin-left: 180px;
    
}
.bot-learn p{
    margin-left: 0;
}
.row2-landing button{
    margin-left: 140px;
    margin-top: 20px;
    background-color: #f2f6f9;
    border-radius: 3px;
    height: 35px;
    color:#C1303E ;
    font-weight: 600;
    margin-bottom: 40px;
    border: 2px solid #C1303E;
    font-family: "montserrat";
    font-size: 12pxs;
}

.bot-learn p{
    font-size: 10px;
    font-weight: bold;
    margin-top: 8px;
    font-family: Montserrat,sans-serif;
    color: #C1303E;
    opacity: 1;
    margin-left: 0;
    width: 80px;
    height: 80px;
   
    
}

.pic1{
    height: auto;
    width: 33%;
 
    background-image: url("/Artboard2.png");
     background-size: 100% 100%;
      background-repeat: no-repeat;
 
    
}
.pic1 img{
    width: 100%;
}

/* .pic1 img{
    
    width: 90%;
} */

.pic2{
    height: auto;
    width: 33%;
    
    background-image: url("/Artboard3.png");
    background-size: 100% 100%;
      background-repeat: no-repeat;
  
}
.pic2 img{
    width: 100%;
}
/* .pic2 img{
    width: 90%;
    
} */
.pic3{
    height: auto;
    width: 33%;
    
    background-image: url("/Artboard4.png");
    background-size: 100% 100%;
      background-repeat: no-repeat;
     
}
.pic3 img{
    width: 100%;
}
/* .pic3 img{
   
    width: 90%;
} */

.pictures{
    background-color: #f2f6f9;
width:auto;
    padding-bottom: 80px;
    padding-left: 120px;
    padding-right: 120px;
   
}
.col-sm-8 h4{
    margin-left:90px;
}
.col-sm-4 button{
    border: 2px solid white;
    background-color:#C1303E;
    border-radius: 8px;
}

.col-sm-3 button{
 border: 2px solid white;
    background-color:#C1303E;
    border-radius: 8px;
    height: 35px;
    width: auto;
    font-weight: bold;
    margin-left: 110px;
}
.fa-refresh{
    color: white;
}
.Myrow3 button:hover{
    background-color: red;
}
.test1{
    padding-left: 30px;
    padding-top: 60px;
}
.Myrow3 button{
    height: 40px;
    width: 150px;
    margin-top: 45px;
    margin-left: 20px;
    border-radius: 8px;
    border:2px solid white;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.col-sm-3 button:hover{
    background: red;
}
#link-pics{
    text-decoration: none;
    color:white;
    font-weight: bold;  
  margin-left: 20px;
  margin-right: 20px;
}

#link-pics:hover{
color: grey;
}

.Myrow5 h4{
    font-size: 14px;
    margin-top: 20px;
 
 
  
    color: white;
    font-weight: lighter;
}
.Myrow5 a{
    color: white;
    text-decoration: underline !important;
    font-weight: bold;
      cursor: pointer;
}
.Myrow5 a:hover{
    color: blue !important;
}
#buttom-menu-drr{
    margin-left: 24px;
    font-size: 13px;
    font-weight:100;
    color: #38465f;
    font-weight: 400;
    
}
#buttom-menu-drr:hover{
    text-decoration: none;
    color: grey;
    
}
.support h4{
    margin-left: 40px;
   
}

.left{
    margin-right: auto;
}
.youth-lab{
    margin-bottom: 20px;
}
.youth-lab img{
     height: 80px;
    width: 200px;
    margin-left: 40px;
    margin-top: 5px;
}

.youth-lab h4{
font-size: 14px;
padding-left: 80px;
font-family: Montserrat,sans-serif;
} 

.DRR{
 
    padding: 0 !important;
}




.mobile-container {
  max-width: 576px;
  margin: auto;
  background-color: #555;
  height: auto;
  color: white;
  border-radius: 10px;
}

.topnav {
  overflow: hidden;
  background-color: #172845;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  margin-left: 20px;
}



.topnav img{
    
    width: 70px;
  height: 60px;
   
   
}

#header{
    margin-left: 0px;
    padding-left: 0px;
    line-height: 1;
    font-weight: bold;
    font-size: 14px;
}
.topnav a.icon {
  background: #172845;
  display: block;
  position: absolute;
  color: white;
  right: 0;
  top: 0;
  margin-right: 10px;
  margin-top: 10px;
}
#topnav-nav-right{
    color: grey;
}
.toggle{
    color: white !important;
}
.active {
  background-color: #36455e;
 
}

.nav-link1{
    background-color: #36455e !important;
}
@media (min-width: 320px) and (max-width: 767px) { 
    .landing-image {
        padding: 0% 7%;
    }
    .row2-landing {
        padding-left: 30px;
        padding-top: 20px; 
        padding-right: 30px;  
    }
    .footer-banner-button {
        display: inline;
        
    }
    .footer-banner-wrapper {
        padding: 5% 5%;
    }
 
}
@media (min-width: 768px) and (max-width: 991px) {
    .Myrow1 h4 {
        padding-left: 67px;
    }
    .Myrow1 button {
        margin-left: 67px;
    }
    .row2-landing h4 {
        padding-left: 67px;
    }
    .row2-landing p {
        padding-left: 67px;
        padding-right: 67px;
    }
    .pictures {
        padding-left: 50px;
        padding-right: 75px;
    }
    .row2-landing button {
        margin-left: 67px;
        margin-bottom: 30px;
    }
}
@media (min-width: 992px) and (max-width: 1199px)  {
    .Myrow1 h4 {
        padding-left: 85px;
    }
    .Myrow1 button {
        margin-left: 85px;
    }
    .row2-landing h4 {
        padding-left: 85px;
    }
    .row2-landing p {
        padding-left: 85px;
        padding-right: 85px;
    }
    .pictures {
        padding-left: 68px;
        padding-right: 75px;
    }
    .row2-landing button {
        margin-left: 85px;
        margin-bottom: 30px;
    }
}
@media (min-width: 1200px) and (max-width: 1419px) {
    .Myrow1 h4 {
        padding-left: 8%;
    }
    .Myrow1 button {
        margin-left: 8%;
    }
    .row2-landing h4 {
        padding-left: 8%;
    }
    .row2-landing p {
        padding-left: 8%;
        padding-right: 8%;
    }
    .pictures {
        padding-left: 6.5%;
        padding-right: 7%;
    }
    .row2-landing button {
        margin-left: 8%;
        margin-bottom: 30px;
    }
}