.footer-banner {
    background-color:  #C1303E;    
}
.footer-banner-wrapper {
    padding: 3.5% 7%;
}

.footer-banner-content h4{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    color: #FFFFFF;
}
.footer-banner-button {
    display: flex;
    justify-content: flex-end;
}
.footer-banner-button .btn{
    background: #172845;
    border-color: #172845;
    padding: 8px 15px;
    margin-top: 15px;
    font-family: "montserrat";
    border-radius: 3px;
    height: 35px;
    font-weight: 600;
    font-size: 12px;
}
.footer-banner-button .btn:hover{
    background-color: #1167b1;
    border-color: #1167b1;
}
.footer-main {
    background-color: #F2F6F9;
    padding: 5% 7.5%;
}
.footer-email {
    margin-bottom: 5%;
}
.footer-email h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
}
.footer-email #buttom-menu {
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    cursor: pointer;
    color: #007BFF;
    padding-left: 10px;
}
.footer-DRR h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    font-family: "montserrat";
    
}
.footer-DRR #buttom-menu-drr {
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #676767;
    text-decoration: underline;
    margin: 0;
    font-family: "montserrat";
}
.footer-youthlab h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    font-family: "montserrat";
}
.footer-youthlab img {
    height: 80px;
    width: 200px;
    margin-top: 5px;
    position: relative;
    right: 20px;
}
.footer-support h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    font-family: "montserrat";
}
.footer-support img {
    height: auto;
    width: 230px;
    position: relative;
    right: 10px;
}
.footer-signup h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    margin-bottom: 15px;
    font-family: "montserrat";
}
.footer-signup input {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #676767;
    padding: 8px 0px 8px 15px;
    margin-right: 10px;
    border: 1px solid #172845;
    border-radius: 3px;
}
.footer-signup .btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    background: #C1303E;
    border-radius: 3px;
    padding: 8px 15px;
    border-color: #C1303E;
    height: 33px;
}
.footer-signup .icons .fa {
    opacity: 1;
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    background-color: #C1303E;
    margin-right: 10px;
}
.footer-signup .icons .fab {
    opacity: 1;
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    background-color: #C1303E;
    margin-right: 10px;
}
.email {
    margin: 20px 0px;
}
.email .fa {
    opacity: 1;
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    background-color: #C1303E;
    margin-right: 10px;
}
.footer-copyright {
    background-color: #172845;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    background-color: #172845;
}
.footer-copyright-content {
    display: flex;
    padding: 0.5% 0% 0% 7.5%;
}
.footer-copyright img{
    height: 45px !important;
    width: 45px !important;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.footer-copyright h4 {
    color: #fff;
    font-size: 14px;
    margin-top: 18px;
    font-family: "montserrat";
}
.footer-copyright h4 a {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline !important;
    color: #ffffff;
}
.footer-copyright h4 a:hover {
    color: #007BFF;
}
.footer-copyright-reserved {
    color: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 1.5% 7% 0% 0%;
    font-size: 14px;
    font-family: "Montserrat";
}
/* media queries */
@media (min-width: 320px) and (max-width: 767px) {
    .footer-banner-content h4 {
        font-size: 16px;
        line-height: 25px;
    }
   .footer-banner-button .btn {
       height: 35px !important;
       margin-top: 10px;
   }
   .footer-DRR {
       margin-bottom: 5%;
   }
   .footer-youthlab {
       margin-bottom: 5%;
   }
   .footer-support {
       margin-bottom: 5%;
   }
   .footer-copyright h4 {
    color: #fff;
    font-size: 10px;
    margin-top: 15px;
    }
    .footer-copyright h4 a {
    font-size: 10px;
    }
    .footer-copyright img {
        height: 35px !important;
        width: 35px !important;
    }
    .footer-copyright-reserved {
        justify-content: flex-start;
        padding: 1.5% 0% 0% 7%;
        font-size: 10px;
    }
    .footer-youthlab img {
        height: 55px;
        width: auto;
        right: 14px;
    }
    .footer-support img {
        height: auto;
        width: 180px;
        position: relative;
        right: 8px;
    }
    .footer-main {
        background-color: #F2F6F9;
        padding: 7% 7.5%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    /* .footer-youthlab img {
        height: auto;
        width: 150px;
    }
    .footer-support img {
        height: auto;
        width: 150px;
    } */
    .footer-signup {
        margin-top: 3%;
    }
    .footer-support {
        margin-top: 3%;
    }
    .footer-signup input {
        width: 50%;
        margin-bottom: 5px;
    }
    .column-width {
        width: 50%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .footer-signup {
        margin-top: 3%;
    }
    .footer-signup input {
        width: 50%;
        margin-bottom: 5px;
    }
    .column-width {
        width: 30%;
    }
}
@media (min-width: 1200px) and (max-width: 1419px) {
    .footer-banner-wrapper {
        padding: 3.5% 8%;
    }
    .footer-main {
        background-color: #F2F6F9;
        padding: 5% 8.5%;
    }
}
