@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600);
/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
body {
  margin: 0;
  font-family: "montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


#guide-active {
  color: silver;
}
#faq-active {
  color: silver;
}
#fellow-active {
  color: silver;
}
#blog-active {
  color: silver;
}
.mun-name{
  word-break: break-word;
}
.hilight-text p {
  background-color: #e5eaee;
  padding-left: 250px;
  padding-right: 150px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.micro-container-guidelines {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-image: url("/MicrosoftTeams-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.guidelines-image {
  height: 170px;
  width: 100%;
  margin-top: 100px;
  opacity: 0.87;
}
.qns {
  background-color: #f2f6f9;
}
.qns p {
  margin-left: 170px;
  margin-right: 280px;
}
.qns ol {
  margin-left: 15%;
  margin-right: 280px;
  line-height: 2;
  font-family: "montserrat";
  font-size: 14px;
}

.test {
  background-color: #e5eaee;
  padding-top: 20px;
  padding-left: 30px;
}
.test p {
  margin-right: 280px;
}

.selection {
  background-color: #e5eaee;
  padding-left: 30px;
}
.selection h4 {
  margin-left: 112px;
}
.selection p {
  margin-left: 175px;
  font-weight: bold;
}
.blog-soon {
  height: 300px;
  padding-left: 15px;
}
.blog-soon p {
  color: #172845;
  opacity: 0.87;
  font-size: 25px;
  font-family: Montserrat, sans-serif;
  font-weight: lighter;
  margin-left: 140px;
  margin-top: 80px;
}

.btn {
  height: 50px;
  width: auto;
}

.col h4 {
  padding-right: 480px;
  margin-left: auto;
}

#online-form {
  text-decoration: underline;
  cursor: pointer;
  color: blue;
}
#online-form:hover {
  color: black;
  text-decoration: underline;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: auto;
  margin-left: 15% !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  font-family: "montserrat";
}

/* added css */
.guidelines-topics {
  display: flex;
  justify-content: space-between;
}
@media (min-width: 768px) and (max-width: 991px) {
  .qns h4{
    margin-left: 7% !important;
}
.qns p{
  margin-left: 7% !important;
  margin-right: 10%;
}
.guidelines-topics {
  display: flex;
  justify-content: space-between;
  overflow-x: scroll;
  margin-right: 10px;
}
.quesn-catagory1 h4 {

border-right: none !important;

}

.test1 {
padding-right: 30px;
padding-top: 50px;
}
.qns {

padding-bottom: 50px !important;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .qns h4{
    margin-left: 15.5% !important;
}
.qns p{
  margin-left: 15.5% !important;
  margin-right: 10%;
}
.quesn-catagory1 h4 {
 
  border-right: none !important;
  
}
}
@media (min-width: 320px) and (max-width: 767px) {
  table {
    
    margin-left: 0% !important;
}
  .guidelines-topics {
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
}
.quesn-catagory1 h4 {
 
  border-right: none !important;
  
}
td,
th {
  font-size: 12px;
}
.test1 {
  padding-right: 30px;
  padding-top: 50px;
}
.qns {
  
  padding-bottom: 50px !important;
}
}
@media (min-width: 1200px) and (max-width: 1419px) {
  .quesn-catagory1 h4 {
 
    border-right: none !important;
    
  }
}
.footer-banner {
    background-color:  #C1303E;    
}
.footer-banner-wrapper {
    padding: 3.5% 7%;
}

.footer-banner-content h4{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 22px;
    line-height: 29px;
    color: #FFFFFF;
}
.footer-banner-button {
    display: flex;
    justify-content: flex-end;
}
.footer-banner-button .btn{
    background: #172845;
    border-color: #172845;
    padding: 8px 15px;
    margin-top: 15px;
    font-family: "montserrat";
    border-radius: 3px;
    height: 35px;
    font-weight: 600;
    font-size: 12px;
}
.footer-banner-button .btn:hover{
    background-color: #1167b1;
    border-color: #1167b1;
}
.footer-main {
    background-color: #F2F6F9;
    padding: 5% 7.5%;
}
.footer-email {
    margin-bottom: 5%;
}
.footer-email h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
}
.footer-email #buttom-menu {
    font-size: 14px;
    line-height: 17px;
    text-decoration: underline;
    cursor: pointer;
    color: #007BFF;
    padding-left: 10px;
}
.footer-DRR h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    font-family: "montserrat";
    
}
.footer-DRR #buttom-menu-drr {
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #676767;
    text-decoration: underline;
    margin: 0;
    font-family: "montserrat";
}
.footer-youthlab h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    font-family: "montserrat";
}
.footer-youthlab img {
    height: 80px;
    width: 200px;
    margin-top: 5px;
    position: relative;
    right: 20px;
}
.footer-support h4 {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    font-family: "montserrat";
}
.footer-support img {
    height: auto;
    width: 230px;
    position: relative;
    right: 10px;
}
.footer-signup h4{
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: #35455E;
    margin-bottom: 15px;
    font-family: "montserrat";
}
.footer-signup input {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #676767;
    padding: 8px 0px 8px 15px;
    margin-right: 10px;
    border: 1px solid #172845;
    border-radius: 3px;
}
.footer-signup .btn {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #FFFFFF;
    background: #C1303E;
    border-radius: 3px;
    padding: 8px 15px;
    border-color: #C1303E;
    height: 33px;
}
.footer-signup .icons .fa {
    opacity: 1;
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    background-color: #C1303E;
    margin-right: 10px;
}
.footer-signup .icons .fab {
    opacity: 1;
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    background-color: #C1303E;
    margin-right: 10px;
}
.email {
    margin: 20px 0px;
}
.email .fa {
    opacity: 1;
    display: inline-block;
    height: 36px;
    width: 36px;
    line-height: 39px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-size: 18px;
    background-color: #C1303E;
    margin-right: 10px;
}
.footer-copyright {
    background-color: #172845;
    padding: 0;
    margin: 0;
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    background-color: #172845;
}
.footer-copyright-content {
    display: flex;
    padding: 0.5% 0% 0% 7.5%;
}
.footer-copyright img{
    height: 45px !important;
    width: 45px !important;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}
.footer-copyright h4 {
    color: #fff;
    font-size: 14px;
    margin-top: 18px;
    font-family: "montserrat";
}
.footer-copyright h4 a {
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline !important;
    color: #ffffff;
}
.footer-copyright h4 a:hover {
    color: #007BFF;
}
.footer-copyright-reserved {
    color: #fff;
    display: flex;
    justify-content: flex-end;
    padding: 1.5% 7% 0% 0%;
    font-size: 14px;
    font-family: "Montserrat";
}
/* media queries */
@media (min-width: 320px) and (max-width: 767px) {
    .footer-banner-content h4 {
        font-size: 16px;
        line-height: 25px;
    }
   .footer-banner-button .btn {
       height: 35px !important;
       margin-top: 10px;
   }
   .footer-DRR {
       margin-bottom: 5%;
   }
   .footer-youthlab {
       margin-bottom: 5%;
   }
   .footer-support {
       margin-bottom: 5%;
   }
   .footer-copyright h4 {
    color: #fff;
    font-size: 10px;
    margin-top: 15px;
    }
    .footer-copyright h4 a {
    font-size: 10px;
    }
    .footer-copyright img {
        height: 35px !important;
        width: 35px !important;
    }
    .footer-copyright-reserved {
        justify-content: flex-start;
        padding: 1.5% 0% 0% 7%;
        font-size: 10px;
    }
    .footer-youthlab img {
        height: 55px;
        width: auto;
        right: 14px;
    }
    .footer-support img {
        height: auto;
        width: 180px;
        position: relative;
        right: 8px;
    }
    .footer-main {
        background-color: #F2F6F9;
        padding: 7% 7.5%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    /* .footer-youthlab img {
        height: auto;
        width: 150px;
    }
    .footer-support img {
        height: auto;
        width: 150px;
    } */
    .footer-signup {
        margin-top: 3%;
    }
    .footer-support {
        margin-top: 3%;
    }
    .footer-signup input {
        width: 50%;
        margin-bottom: 5px;
    }
    .column-width {
        width: 50%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .footer-signup {
        margin-top: 3%;
    }
    .footer-signup input {
        width: 50%;
        margin-bottom: 5px;
    }
    .column-width {
        width: 30%;
    }
}
@media (min-width: 1200px) and (max-width: 1419px) {
    .footer-banner-wrapper {
        padding: 3.5% 8%;
    }
    .footer-main {
        background-color: #F2F6F9;
        padding: 5% 8.5%;
    }
}

.logo-link {
    display: flex;
}
.logo-link img {
    width: 80px;
    height: 80px;
    margin-left: 0px;
    object-fit: contain;
}
.nav-wrapper {
    padding: 0% 7%;
}
#nav-left {
    margin-left: 0;
    color: #ffffff;
    opacity: 1;
    font-size: 14px;
    line-height: 1;
    font-family: Montserrat, sans-serif;
    font-weight: bold;
    margin-top: 20px;
}
#responsive-navbar-nav {
    justify-content: flex-end;
}
.navbar-dark .navbar-nav .nav-link {
    color: #fff;
}
.navbar-dark .navbar-toggler {
    color: #fff;
    border: none;
}
.navbar-toggler:focus {
    box-shadow: none;
}
.navbar-nav {
    font-family: "montserrat";
}
/* media queries */
@media (min-width: 321px) and (max-width: 767px) {
    .logo-link img {
        width: 55px;
        height: 55px;
        margin-left: 0px;
        object-fit: contain;
    }
    #nav-left {
        margin-top: 10px;
        padding-left: 0px;
        font-size: 12px !important
    }
}
@media (max-width: 320px) {
    .logo-link img {
        width: 60px;
        height: 60px;
        margin-left: 0px;
        object-fit: contain;

    }
    #nav-left {
        margin-top: 10px;
        padding-left: 0px;
        font-size: 8px !important;
    }
}
* {
  box-sizing: border-box;
}

.fellowsCoverPicContainer{
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-image: url('/fellowsBanner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 600px;
    position: relative;
}
.fellows-image{
    background-color:#172845;
    opacity:0.87;
    height:110px;
    position: absolute;
    bottom: 0;
}
.fellows-image h4{
    margin-top: 30px !important;
}
@media only screen and (max-width: 576px){
    .fellows-image h4{
        margin-top: 40px !important;
    }
}
.selectedYear{
    font-weight: bold;
    color: #002A59;
}
.background{
   
    margin: 0;
    padding:0;
    width: 100vw;
    height: 400px;
    background-color: #36455e;
  
    
}
.det h6{
    opacity: 0.67;
    font-family: "montserrat";
    margin-top: 5px;
    font-size: 14px;
}
.nav-bar{
    background-color: #172845;
    width: 100vw;
}
.nav-left{
    color: #FFFFFF;
    opacity: 1;
    font-size: 16px;
    font-family: Montserrat,sans-serif;
}
.det{
    margin-top: 25px;
    margin-bottom: 25px;

}
.det p{
    font-size: 16px;
    padding-right: 50px;
    margin-top:30px;
    font-family: "montserrat";
    line-height: 28px;
}
.det h4{

    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
  margin-bottom: 0px;
    
}
.det a{
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #C1303E;
    text-decoration: none;
}
.det .fa {
    color: #C1303E;
    opacity: 1;
    margin-left: 5px;
    font-size: 12px;
}


.fellow-row{
    background-color: #f2f6f9;
    opacity: 1;
    height: auto;
    width: 100vw;
    margin: 0;
    padding: 0;
    
    
   
}
.fellows{
    height: auto;
    width: auto;
  margin-top: 15px;
  margin-bottom: 20px;
    margin-right: 350px;
    background-color: #FFFFFF;
 
 display: flex;
 flex-direction: row;
}
.colm-right{
  
   margin-top: 60px;
  margin-bottom: 60px;

}
.foo{
    height: 5px;
}
.foo img{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-left: 250px;

}
.fellows-year{
   padding-top: 80px;
  
}
.fellows-year h4{
    padding-left: 130px;
    font-size: 16px;
    font-family: "montserrat";
}
.fellows-year p{
    padding-left: 130px;
    margin-bottom: 5px;
    font-family: "montserrat";
}
.fellows-year hr{
    width: 120px;
    margin-top: 0px;
    margin-left: 130px;
}
.fellows img{
 margin-left: 30px;
 margin-top: 20px;
 border-radius: 50%;
 margin-right: 30px;
border: 1px solid silver;
  
  
}
.nation-challenge{
    background-color: #c1303e;
   
    width: 100vw;
    height: auto;
    margin: 0;
    padding: 0;
    padding-top: 30px;
    padding-bottom: 30px;
}
.nations-text{
    color: #FFFFFF;
    opacity: 1;
    height: 100px;
}
.nations-text h4{
   justify-content: center;
}

.last-part{
    background-color: #f2f6f9;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: auto;
}
.nav-right{
    line-height: 0.5;
}

.grid{
  
    height: 100vh;
    width: 100vw;
    display: grid;
    padding: 10px;
    grid-template-rows: 90px 380px 1150px 400px 300px 300px 70px ;
    margin: 0;
    padding: 0;
   overflow-x: hidden;

}
.footer img{
    height: 50px;
    width: 50px;
    border-radius: 50%;
}


.class6{

  
   
    background-color: #f2f6f9;
    
    width: 100%;
    height: 300px;
}
.col1{
    height: 150px;
    width: 200px;
  
    margin-top: 100px;
   
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.col1 h2{
    color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col1 a{
  
    margin-bottom: 10px;
    color:#707070;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col1 a:hover{
    color: silver;
}
.col2{
   height: 150px;
    width: 380px;
   
     margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    color: #172845;
    display: flex;
    flex-direction: column;
   
    font-family: Montserrat,sans-serif;
}
.col2 h2{
 margin-top: 8px;
    color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col2 a{
    color: #707070;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
     text-decoration: underline;
}
.col2 a:hover{
    color: silver;
}
.col3{
    height: 150px;
    width: 300px;
    
     margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
}
.col3 h2{
    margin-top: 8px;
    color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.col3 input{
   padding: 10px;
   padding-right: 120px;
   background-color: #f2f6f9;
   border: 1px solid grey;
}
.col4 h2{
    margin-top: 8px;
        color: #172845;
    font-size: 20px;
    font-family: Montserrat,sans-serif;
}
.buttonxo {
  background-color: #C1303E;
  border: none;
  color: white;
  padding: 5px;
 margin-top: 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  font-family: Montserrat,sans-serif;
 outline: none;
  cursor: pointer;
}
.button2121{
    border-radius: 12px;
    
}
.button2121:hover{
    background-color: red;
}
.button2121 {
  background-color: #C1303E; 
  color: #F2F6F9; 
  border: 2px solid #F2F6F9;
}
.col4{
   height: 150px;
    width: 300px;
  
     margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;;
}
.col4 h2{
    font-size: 20px;
    font-family: Montserrat,sans-serif;
    color: #172845;
}
.col4 img{
    height: 100px;
    width: 200px;
    border-radius: 8px;
    padding-top: 0;
    margin-top: 0;
}



.background h4{
    color: white;
    opacity: 0.87;
    margin-top: 150px;
    margin-left: 190px;

}

@media only screen and (max-width: 576px) {
    .guidelines-image{
   
        
        margin-top: 0px;
       
    }
    .micro-container{
      
        background-image: url('/fellowship_image_mobile.png')!important;
    }
.fellows-year h4{
    padding-left: 0px;
}
.fellows-year p{
    padding-left: 0px;
}
.fellows-year hr{
    width: 330px;
    margin-left: 0px;
}
.colm-right{
    margin-top: 0px;
    margin-bottom: 30px;
}
.fellows{
    margin-right: 0px;
}
.det p{
    padding-right: 15px;
}
}
@media (min-width: 320px) and (max-width: 767px)  {
    .fellows {
        display: flex;
        flex-direction: column;
    }
    .det h4 {
        padding-left: 10%;
    }
    .det h6 {
        padding-left: 10%;
    }
    .det p {
        padding-left: 10%;
    }
    .det a {
        padding-left: 10%;
    }
    .fellows-year {
        padding-left: 10%;
    }
    .colm-right {
        padding: 0% 10%;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .fellows-year h4 {
        padding-left: 10%;
    }
    .fellows-year p {
        padding-left: 10%;
    }
    .fellows-year hr {
        margin-left: 10%;
    }
    .fellows {
        margin-right: 5%;
    }
}
@media (min-width: 992px) and (max-width: 1199px) {
    .fellows-year h4 {
        padding-left: 10%;
    }
    .fellows-year p {
        padding-left: 10%;
    }
    .fellows-year hr {
        margin-left: 10%;
    }
    .fellows {
        margin-right: 5%;
    }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}
.content {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.linking-bipad {
  text-decoration: underline;
  cursor: pointer;
}
.linking-bipad:hover {
  color: blue;
}
.Myrow2 p {
  padding-left: 17.7% !important;
  padding-right: 370px !important;
  font-family: "montserrat";
  line-height: 28px;
}
@media only screen and (max-width: 576px) { 
  * {
    box-sizing: border-box !important;
  }
  .Myrow2 p {
    padding-left: 30px !important;
    padding-right: 30px !important;
    line-height: 1.5;
  }
  .H4 h4 {
    margin-top: 0px !important;
    margin-left: 50px !important;
  }
  .blog-images{
    width: 100% !important;
  }
  .blog-box img{
    width: 100% !important;
    object-fit: contain;
  }
  #intro-image{
    /* width: 100% !important;
    height: 200px !important; */
    object-fit: contain !important;
    margin-top: 0px !important;

  }
  .H4 h6 {
    margin-left: 10px !important;
    margin-bottom: 20px !important;
  }
  /* .col-sm-8 h4{
    margin-left: 0px !important;
  } */

  .inside-content h4 {
    padding-left: 10px !important;
    margin-top: 20px;
    font-size: 16px;
    margin-right: 30px;
  }
  .inside-content p {
    padding-left: 0px !important;
    padding-right: 20px;
  }
.pic1{
  width: 100% !important;
  padding-bottom: 20px;
  margin-left: 30px;
}
.pic2{
  width: 100% !important;
  padding-bottom: 20px;
  margin-left: 30px;
}
.pic3{
  width: 100% !important;
  padding-bottom: 50px;
  margin-left: 30px;
}
  .pic1 img {
    width: 100% !important;

  }
  .pic2 img {
    width: 100% !important;
 
   
  }
  .pic3 img {
    width: 100% !important;
  
  }
  .Myrow3{
    padding-left: 20px !important;
    padding-right: 20px;
  }
  .Myrow3 button{
    margin-left: 10px !important;
  }
  .Myrow1 button {
    margin-left: 10px !important;
  }

  .row2-landing button {
    margin-left: 20px !important;
  }
  .youth-lab img {
    height: 50px !important;
    width: 100px !important;
  }
  .Myrow5 img {
    margin-left: 0px !important;

    margin-right: 0 !important;
    height: 25px !important;
    width: 25px !important;
  }
  .col-sm-3 button {
    margin-left: 60px;
  }
  .row2-landing h4 {
    padding-left: 20px !important;
    font-size: 20px !important;
  }
  .row2-landing p {
    padding-left: 20px !important;
    font-size: 16px !important;
    padding-right: 30px !important;
  }
  .ray h4 {
    margin-left: 30px !important;
    margin-top: 70px !important;
    font-size: 20px !important;
    margin-top: 60px !important;
  }

  .blog-soon p {
    margin-left: 20px !important;
    margin-top: 140px !important;
    margin-right: 20px !important;
  }
  .quesn-catagory1 {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
  }
  /* .quesn-catagory1 h4{
    display: none;
  } */
  .vl{
    display: none;
  }
  .quesn-catagory {
    padding-left: 0px !important;
  }
  .quesn-catagory1 button{
    padding-left: 40px;
    text-align: left;
  }
  .ray-fellows h4 {
    margin-left: 30px !important;
    margin-top: 70px !important;
    font-size: 20px !important;
  }

  .quesn-catagory h4 {
    margin-left: 40px !important;
  }

  .micro-container-guidelines {
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-image: url("/MicrosoftTeams-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  /* #overview1 {
    margin-left: 40px !important;
    margin-right: 80px !important;
    padding-left: 0px !important;
  } */
  /* #online-application {
    margin-left: 110px !important;
  }
  #ref-guidelines {
    margin-left: 110px !important;
  } */
  /* #download {
    margin-left: 115px !important;
  } */
  .qns h4 {
    margin-left: 5px !important;
    margin-right: 20px !important;
    font-size: 18px !important;
  }
  .qns p {
    margin-left: 5px !important;
    margin-right: 30px !important;
  }
  .qns ol {
    margin-left: 5px !important;
    margin-right: 30px !important;
  }

  .ray {
    height: auto !important;
  }
  .ray1 {
    height: 190px !important;
  }
  .ray1 h4 {
    margin-left: 10px !important;
    margin-top: 50px !important;
  }
  .ray-fq {
    height: auto !important;
  }
  .DRR h4 {
    margin-left: 20px !important;
  }

  .mail h4 {
    padding-left: 5px !important;
  }
  #buttom-menu {
    padding-left: 5px !important;
  }
  .signup h4 {
    padding-left: 5px !important;
  }
  .signup input {
    margin-left: 5px !important;
  }
  .col-sm-3 button {
    margin-left: 5px !important;
  }
  .youth-lab h4 {
    padding-left: 10px !important;
  }
  .youth-lab img {
    margin-left: 0 !important;
    padding-left: 0 !important;
  }
  .support h4 {
    margin-left: 10px !important;
  }
  .support img {
    margin-left: 0 !important;
    height: 50px !important;
    width: 100px !important;
  }
  .ray-fq h4 {
    margin-top: 70px !important;

    padding-left: 40px !important;
    font-size: 20px !important;
  }
  .Myrow5 h4 {
    padding-left: 10px !important;
    font-size: 8px !important;
    margin-top: 12px !important;
  }
  
  .pictures {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }
  .container{
    padding-left: 30px;
  }
  .ray-fellows {
    height: 190px !important;
  }
  .btn {
    height: 30px !important;
    width: auto !important;
  }
  .quesn-catagory a {
    margin-left: 10px !important;
    margin-right: 0px !important;
  }
  .quesn-catagory1 h4 {
    margin-left: 30px !important;
  }

  /* #elig {
    margin-left: 110px !important;
  } */
  /* #key-res {
    margin-left: 110px !important;
  } */
  .quesn-catagory1 a {
    margin-left: 10px !important;
    padding-left: 20px !important;
  }
  /* #applicationsum {
    margin-left: 110px !important;
  }
  #application-process {
    margin-left: 110px !important;
  } */
  .Myrow4 {
    height: auto;
    width: auto;

    padding-top: 80px;
    padding-bottom: 40px;
    background-color: #f2f6f9;
    padding-left: 10px !important;
    padding-right: 130px;
  }
  #financial-info {
    /* margin-left: 110px !important; */
    margin-bottom: 20px;
  }
  .paragraph-ans {
    font-size: 15px !important;
    color: black !important;
  }
  /* .fa {
    color: black !important;
    font-size: 15px !important;
    height: 25px !important;
  } */
  .micro-container {
    padding-left: 0px !important;
  }
  .Myrow1 h4 {
    padding-left: 10px !important;
    /* margin-top: 20px !important; */
    font-size: 16px !important;
  }
  .Myrow3 h4 {
   
    font-size: 18px !important;
    margin-right: 20px !important;
  }
  #link-pics {
    margin-left: 0 !important;
  }
  .frequent h4 {
    font-size: 18px !important;
    margin-left: 20px !important;
  }
  .questions-answers {
    width: 350px !important;
    margin-left: 12px !important;
  }
  .paragraph-qus {
    font-size: 15px !important;
    padding-right: 70px !important;
  }
  .navbar img {
    margin-left: 0 !important;
  }
  .right {
    margin-left: 10px !important;
  }
  /* #nav-left {
    margin-left: 10px !important;
    font-size: 12px !important;
  } */
  .tab {
    margin-left: 0px !important;
  }

  .IMG img {
    margin-left: 80px !important;
    margin-top: 20px !important;
    margin-bottom: 0px !important;
    left: 0% !important;
  }
}

.inside-content {
  padding-left: 70px !important;
}

.navbar {
  width: auto;
}
.navbar-brand {
  margin-top: 20px;
  margin-right: 0;
}
.Myrow1 {
  padding: 0;
  margin: 0;
  width: auto;
  padding-left: 15px;
  background-color: #36455e;
}
.ray {
  padding: 0;
  margin: 0;
  height: 400px;
  width: auto;
  background-color: #36455e;
}
.ray-fellows {
  padding: 0;
  margin: 0;
  height: 400px;
  width: auto;
  background-color: #36455e;
}

.row button:focus {
  outline: none;
}

.Myrow3 {
  height: auto;
  width: auto;
  background-color: #c1303e;
  padding-left: 130px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.Myrow3 h4 {
  /* padding-top: 40px;
  padding-bottom: 40px;
  padding-left: 140px;
  padding-right: 10px; */
  color: white;
  font-size: 24px;
  font-family: Montserrat, sans-serif;
  opacity: 1;
  font-weight: bold;
}
.bot {
  margin-top: 50px;
  height: 50px;
  width: 200px;
  color: #c1303e;
}
.bot1 {
  margin-top: 50px;
  height: 50px;
  width: 150px;
  color: #c1303e;
  border-radius: 8px;
}
.bot1 p {
  font-size: 15px;
  padding-left: 5px;
  margin: 5px;
  font-family: Montserrat, sans-serif;
  color: white;
}

.bot p {
  font-size: 15px;
  padding-left: 5px;
  margin: 5px;
  font-family: Montserrat, sans-serif;
  color: #ffffff;
  opacity: 0.87;
}
.Myrow4 {
  height: auto;
  width: auto;

  padding-top: 80px;
  padding-bottom: 40px;
  background-color: #f2f6f9;
  padding-left: 110px;
  padding-right: 110px;
}
/* .col-sm-3{
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 0px;
   
    
} */

.support img {
  height: 100px;
  width: 240px;
}

.Myrow5 {
  padding: 0;
  margin: 0;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  background-color: #172845;
  opacity: 0.86;
}
.Myrow5 img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.logo img {
  margin-left: 150px;
}

.col-sm-6 {
  margin-top: 15px;
  margin-bottom: 10px;
}
.col-sm-4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.col-sm-6 h4 {
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  color: #707070;
}
.navbar-brand img {
  margin-left: 50px;
}
#nav-left {
  margin-left: 0;
  color: #ffffff;
  opacity: 1;
  font-size: 14px;
  line-height: 1;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
}
.right {
  margin-right: 115px;

  opacity: 1;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  margin-left: 170px;
}
#nav-right {
  color: #ffffff;
}
.H4 h4 {
  font-size: 30px !important;
  color: #ffffff;
  opacity: 0.87;
  font-family: Montserrat, sans-serif !important;
  font-weight: bold;

  margin-top: 120px;

  margin-left: 0px;
}
.H4 h6 {
  font-size: 16px !important;
  color: #ffffff;
  opacity: 0.87;
  font-family: Montserrat, sans-serif !important;
  margin-left: 0px;
  margin-bottom: 100px;
}
.IMG img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  /* margin-top: 70px;
  margin-left: 270px;
  margin-bottom: 30px; */
  position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(0%,-50%);
          transform: translate(0%,-50%);
}

#fellow-name h4 {
  margin-top: 80px;
  margin-left: 0px;
  padding-left: 0px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .Myrow2 p{
    padding: 0% 10% !important;
    line-height: 28px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .IMG img {

    margin-left: 15%;
    left: 0% !important;
 
}
#fellow-name h4 {
  margin-top: 18%;

}
.Myrow2 p {
  padding-left: 10% !important;
  padding-right: 8% !important;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .IMG img {
    left: 0% !important;
    margin-left: 19%;
 
}
#fellow-name h4 {
  margin-top: 15%;

}
.Myrow2 p {
  padding-left: 9% !important;
  padding-right: 8% !important;
}
}

.blog-author {
  background: #ffffff;
  padding: 2% 7.5%;
  box-shadow: 0px 0px 29px rgba(23, 40, 69, 0.13);
}
.author-detail {
  display: flex;
  /* margin-bottom: 30px; */
}
.author-by {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
  margin-bottom: 5px;
}
.author-name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #35455e;
  margin-bottom: 10px;
}
.author-description {
  padding: 1% 0%;
}
.blog-author-info {
  padding-top: 15px;
}
.blog-author-date {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #676767;
}
.blog-author-date p{
  margin-bottom: 10px;
}
.blog-author-img img {
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin-right: 15px;
  margin-top: 15px;
}
.author-quote {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 34px;
  color: #35455e;
  margin-left: 55px;
  margin-top: -15px;
}
.blog-content {
  background-color: #f2f6f9;
  padding: 5% 25% 0% 25%;
  text-align: justify;
}
.blog-recent p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #35455E;
  margin-bottom: 30px;
}
.blog-recent-article {
  display: flex;
  margin-bottom: 25px;
}
.blog-recent-article img {
  border-radius: 6px;
  width: 150px !important;
  height: 150px !important;
  margin-right: 15px;
  object-fit: cover;
  
}
.blog-recent-article-content {
  margin-right: 30px;
}
.blog-recent-article-content p {
 width: 200px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #35455E;
  margin-bottom: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-align: left;
  text-align: initial;
}
.blog-recent-article-content a {
 font-family: "montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 23px;
  color: #C1303E !important;
  cursor: pointer;
  letter-spacing: 0.06em;
}
.blog-recent-article-content .fa {
  color: #C1303E;
  opacity: 1;
  margin-left: 5px;
  font-size: 12px;
}
.blog-recent-article-detail span{
  font-size: 18px !important;
  line-height: 30px;
  color: #676767;
}
.blog-recent-article-detail h1{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #35455E;
  margin-bottom: 30px;
}
.blog-recent-article-detail p {
  font-family: "Montserrat";
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 28px;
color: #676767;
margin-bottom: 25px;
}
#intro-image{
width: 100% ;
height: 500px  ;
object-fit: cover;
margin-top: 25px;
}
/* .blog-recent-article-detail img {
width: 100% !important;
height: 200px !important;
object-fit: cover;
margin-top: 25px;

} */
.blog-recent-article-detail-image-2nd img{
width: 100% !important;
height: 200px !important;
object-fit: cover;
margin-top: 25px;

}
.blog-images{
  width: 33%;
}
.modal-body {
  overflow: visible;
  overflow: initial;
}
.modal-body img{
  width: 100%;
  height: calc(100vh - 300px);
  object-fit: contain;
}
.modal-body p{
  margin-top: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  font-style: italic;
}
.modal-content {
 

  background-color: transparent;

  border: none;

}
.video-background {
  /* background-image: url("/videobanner.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 400px; */
  background-color: #333;

}
video{
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  width: inherit;
}
.video-background svg {
  position: relative;
top: 50%;
left: 50%;
-webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 25% 0% 25%;
  text-align: justify;
}
.blog-recent-article-detail-image-2nd {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.blog-recent-article-detail-image-2nd img{

  border-radius: 6px;
  margin-right: 30px;
}
.design-quote{
  margin-bottom: 20px;
}
.blog-recent-article-detail .design-quote .author-quote{
  font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 38px;
   margin-left: 55px;
   color: #35455e;
}
/* .author-svg{
  margin-left: 30px;
} */
.design-quote-wrapper {
  padding: 5px 0px;
}
.recent-article {
  background-color: #ffffff;
  padding: 3% 7% 3% 7%;
}
.recent-article h3 {
  color: #35455E;
  font-family: "montserrat";
}
.photo-credit {
  font-size: 14px !important;
  margin-bottom: 50px !important;
  margin-top: 10px;
  font-style: italic !important;
}
@media (min-width: 220px) and (max-width: 319px) {
  .blog-content {
    background-color: #f2f6f9;
    padding: 10% 5% 0% 5%;
    text-align: justify;
  }
  .blog-content-2nd {
    background-color: #f2f6f9;
    padding: 0.5% 5% 0% 5%;
    text-align: justify;
  }
  .recent-article {
    background-color: #f2f6f9;
    padding: 0% 5% 5% 5%;
    text-align: justify;
  }
  .blog-recent-article-detail-image-2nd {
    display: inline-block;
    
}
.blog-images{
  width: auto;
}
}
@media (min-width: 320px) and (max-width: 767px) {
  .blog-author-info {
    padding: 10px 0px;

  }
  .author-description {
    padding: 0px 0px;
   
}

.author-svg svg {
  width: 25px;
}
.author-quote {
  font-size: 16px;
  line-height: 30px;
  margin-left: 40px;
}
.video-background svg  {
  width: 50px;
}
.blog-recent-article-detail {
  margin-top: 25px;
}
.blog-recent-article-detail img {
    width: 100% !important;
    height: 195px !important;
    
}
.blog-recent-article-detail-image-2nd img {
      margin-top: 30px;
  }
  .blog-recent-article-detail p {
    font-size: 14px;
}

.author-detail {
  padding-left: 10px;
}
  /* .design-quote {
    height: 150px;
} */
.design-quote-wrapper {
  padding: 0px 0px;
}
  .blog-recent-article-detail .design-quote .author-quote {
    line-height: 24px;
    font-size: 14px;
    margin-left: 35px;

}
.blog-content {
  background-color: #f2f6f9;
  padding: 10% 5% 0% 5%;
  text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 5% 0% 5%;
  text-align: justify;
}
.recent-article {
  background-color: #ffffff;
  padding: 8% 5% 5% 5%;
  text-align: justify;
}
.blog-recent-article-detail-image-2nd {
  display: inline-block;
  
}
}
@media (min-width: 768px) and (max-width: 991px) {
  .blog-content {
    background-color: #f2f6f9;
    padding: 5% 10% 0% 10%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 10% 0% 10%;
  text-align: justify;
}
.recent-article {
  background-color: #f2f6f9;
  padding: 5% 7% 5% 7%;
  text-align: justify;
}
.blog-recent-article img {
  width: 100px !important;
  height: 100px !important;
}
.blog-recent-article-content p {
  width: 150px;
  font-size: 14px;
}
.blog-recent-article-content a {
  font-size: 12px;
}
  .author-quote {
    font-size: 18px;
    line-height: 32px;
    
  }
  .blog-recent-article-detail img {
    width: 100% !important;
    height: 190px !important ;
}
.blog-recent-article-detail .design-quote .author-quote {
  font-size: 16px;
  line-height: 30px;
}
}
@media (min-width: 992px) and (max-width: 1199px) {
  .blog-content {
    background-color: #f2f6f9;
    padding: 5% 10% 0% 10%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 10% 0% 10%;
  text-align: justify;
}
.recent-article {
  background-color: #ffffff;
  padding: 5% 7% 5% 7%;
  text-align: justify;
}
  .blog-recent-article-detail img {
    width: 100% !important;
}
/* .design-quote {
  height: 180px;
} */
.blog-recent-article img {
  width: 90px !important;
  height: 90px !important;

}
}
@media (max-width: 320px) {
  .blog-author-info {
    padding: 10px 0px;

  }

.author-svg {
  margin-left: 10px;
}
.author-svg svg {
  width: 25px;
}
.author-quote {
  font-size: 16px;
  line-height: 30px;
  margin-left: 40px;
}
.blog-recent-article-detail {
  margin-top: 25px;
}

.blog-recent-article-detail .blog-recent-article-detail-image img {
      width: 190px !important;
      height: auto !important;
  }
  /* .design-quote {
    height: 220px;
} */
.design-quote-wrapper {
  padding: 0px 0px;
}
  .blog-recent-article-detail .design-quote .author-quote {
    line-height: 24px;
    font-size: 14px;
    margin-left: 42px;

}
 .blog-recent-article-detail img {
  width: 100% !important;
  
}
.video-background {
  height: 160px;
}
}
@media (min-width: 1200px) and (max-width: 1419px){
  .blog-content {
    background-color: #f2f6f9;
    padding: 5% 13% 0% 13%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 13% 0% 13%;
  text-align: justify;
}
.recent-article {
  background-color: #ffffff;
  padding: 5% 8% 5% 8%;
  text-align: justify;
}
.blog-recent-article img {
  width: 100px !important;
  height: 100px !important;
}
}

@media (min-width: 1420px) and (max-width: 1519px){
  .blog-content {
    background-color: #f2f6f9;
    padding: 10% 15% 0% 15%;
    text-align: justify;
}
.blog-content-2nd {
  background-color: #f2f6f9;
  padding: 0.5% 15% 0% 15%;
  text-align: justify;
}
.recent-article {
  background-color: #f2f6f9;
  padding: 0% 15% 5% 15%;
  text-align: justify;
}
}
.ray h4{
    color: #FFFFFF;
    font-size: 55px;
    font-family: Montserrat,sans-serif;
    margin-top: 50px;
    margin-left: 7%;  
}
.ray1 h4{
    color: #FFFFFF;
    opacity: 0.87;
    font-size: 55px;
    font-family: Montserrat,sans-serif;
    margin-top: 100px;
    margin-left: 125px;  
    
}
.ray1{
    background-color:  #36455e;
    height: 400px;
    padding-left: 15px;
}
.ray-fq {
     color: #FFFFFF;
   
    font-size: 55px;
    font-family: Montserrat,sans-serif;
    background-color:  #36455e;
      
    height: 400px;
}
.ray-fq h4 {
    color: #FFFFFF;
    font-size: 55px;
    font-family: Montserrat,sans-serif;
   
    padding-left: 7%;
    padding-right: 20px;
    margin-bottom: 100px;
    margin-top: 20px;
}
.ray-fellows h4{
    color: #FFFFFF;
    font-size: 40px;
    font-family: Montserrat,sans-serif;
    margin-top: 50px;
    margin-left: 7%;  
    }

.Myrow2{
    height: auto;
    background-color: #f2f6f9;
    padding-top: 40px;
    padding-bottom: 40px;
}
.Myrow2 h4{
    margin-left: 20px;
    margin-bottom: 30px;
    color: #172845;
    opacity: 0.87;
    font-size: 30px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
}

.DRR{
    margin-bottom: 20px;
}


.DRR h4{
margin-left: 24px;
font-size: 14px;

font-family: Montserrat,sans-serif;
}

.mail{
    margin-left: 0px;
    
}

.mail h4{
    font-size: 14px;
font-family: Montserrat,sans-serif;
padding-left: 10px;

}
.signup h4{
   font-size: 14px;

padding-left: 110px;
font-family: Montserrat,sans-serif;

}
.signup{
    margin-bottom: 20px;
}
.signup input{
    height: 30px;
    font-size: 12px;
    padding-right: 40px;
    margin-left: 110px;
    margin-bottom:5px;
}
.support h4{
       font-size: 14px;

font-family: Montserrat,sans-serif;
}
.questions-answers{
   height: auto;
   margin:20px;
   margin-left: 10.5%;
   width: 900px;
   background-color: white;
   
}
.paragraph-ans{
   margin-right: 120px;
   padding-bottom: 10px;
   font-size: 18px;
   line-height: 1.6 !important;
   color: #000000DE;
   opacity: 0.87 !important;
   font-family: Montserrat,sans-serif;
   margin-left: 30px;
   
}
.paragraph-qus{
    margin-left: 35px;
    color: #172845;
    opacity: 0.87;
    font-size: 20px;
    font-weight: bold;
    font-family: Montserrat,sans-serif;
    margin-top: 10px;
}
.application-process{
    margin-top: 20px;
   
 
}

.fa{
color: #35455E;
font-size: 25px;
}

.ques1 button{
    border: none;
    background-color: white;
    margin-left: auto;
    margin-right: 30px;
}
.ques1{
    display: flex;
}
.quesn-catagory{
    height: auto;
    background-color: white;
    display: flex;
  
}
.quesn-catagory a{
    text-decoration: none;
}
.quesn-catagory1{
    height: auto;
    display: flex;
    padding: 0% 6.9%;
    background-color: white;
}
.quesn-catagory1 a{
    text-decoration: none;
}
.quesn-catagory{
    padding-left: 25px;
}
.quesn-catagory a{
margin-left: 18px;
margin-top: 20px;
font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
margin-bottom: 15px;
}

.quesn-catagory1 a{
margin-left: 25px;
margin-top: 20px;
margin-bottom: 20px;
font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
display: -webkit-box;
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;


}
.quesn-catagory a:hover{
text-decoration: none;
}

.quesn-catagory1 a:hover{
text-decoration: none;
}

.quesn-catagory button{
    font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
margin-top: 5px;
}


.quesn-catagory1 button{
    font-size: 14px;
color: #172845;
opacity: 0.87;
font-family: Montserrat,sans-serif;
font-weight: normal;
margin-top: 10px;
}

.quesn-catagory img{
    height: 20px;
    width: 20px;
    margin-left: 5px;
}

.quesn-catagory1 img{
    height: 20px;
    width: 20px;
    margin-left: 5px;
}


.quesn-catagory h4{
    margin-left: 104px;
    margin-top: 20px;
    padding-right: 20px;
    color: #35455E;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    font-weight: normal;
}

.quesn-catagory1 h4{
    margin-top: 20px;
    padding-right: 10px;
    color: #35455E;
    font-size: 14px;
    font-family: Montserrat,sans-serif;
    font-weight: normal;
    border-right: 2px solid #C1303E;
    height: 25px;
}

.fa{
        color: #35455E;
    font-size: 15px;
    opacity: 0.67;
}




.vl {
  border-left: 1px solid #36455e;
  height: 30px;
  padding-top: 10px;
  font-weight: normal;
  margin-top: 11px;
  
}


.qns{
    width: auto;
    padding-bottom: 80px;
}

.qns h4{
    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
    margin-left: 15%;
    margin-bottom: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.qns p{
    margin-left: 15%;
    color: #000000DE;
    opacity: 1;
    line-height: 28px;
    font-size: 16px;
    font-family: "montserrat";
   
}
.frequent p{
    font-family: "montserrat";
    color: #000000DE;
    opacity: 1;
    line-height: 1.5;
    font-size: 16px;  
    
}
.frequent{
    width: auto;
    padding-bottom: 30px;
    background-color: #f2f6f9;
}

.frequent h4{
    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
    margin-left: 10.5%;
    margin-bottom: 50px;
    margin-top: 50px;
}

#buttom-menu{
    padding-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}
#buttom-menu:hover{
color: blue;
text-decoration: underline;
}

.mail{
    margin-bottom: 20px;
}
@media (min-width: 320px) and (max-width: 767px) {

}
@media (min-width: 768px) and (max-width: 991px) {
    .questions-answers {
        width: auto;
    }
    .quesn-catagory h4 {
        margin-left: 25px;
    }
}
@media (min-width: 992px) and (max-width: 1199px)  {
    .questions-answers {
        width: auto;
    }
    .quesn-catagory h4 {
        margin-left: 45px;
    }
}


.collapsible {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    background-color: #f1f1f1;
}
.Myrow1 h4{
    color: #FFFFFF;
    opacity: 1;
    font-size: 22px;
    font-family: Montserrat,sans-serif;
    margin-top: 450px;
    /* margin-top: 20px; */
   padding-left: 145px;
   padding-right: 20px;
   padding-top: 30px;

    
}
.landing-row{
    padding-left: 0px !important;
}
.micro-container{
    padding-right: 0px !important;
    padding-left: 0px !important;
    background-image: url('/fellowship_image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 600px;

  
  
}
.landing-image{
    background-color: #172845;
    opacity: 0.87;
    height: 180px;
}
/* .landing-image{
    margin-top: 30px;
    background-color: #172845;
    opacity: 0.67;
} */
/* .landing-image{
    position: absolute;
   bottom: 0%;
    width: 100%;
    background-color: grey;
    opacity: 0.67;
} */
.navbar{
   
    background-color: #172845 !important;
   
}

#buttom-menu:hover{
    color: silver;
    text-decoration: none;
}
#nav-right:hover{
    color: grey;
}
#nav-left:hover{
    color: grey;
}
.Myrow1 button{
    margin-left: 145px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: "montserrat";
    background-color: #C1303E;
    border-radius: 3px;
    border:1px solid #C1303E;
    height: 35px;
    width: 100px;
    font-weight: 600;
    font-size: 12px;

}

.Myrow1 button:hover{
    background-color: red;

}

.row2-landing{
    background-color: #f2f6f9;
    height: auto;
    padding-left: 16px;
    padding-top: 50px;
}
.row2-landing button:hover{
    background-color: red;
   
}
.bot-learn p:hover{
    color: white;
}
.row2-landing h4{
    margin-top: 30px;
   padding-left: 140px;
   padding-right: 20px;
    margin-bottom: 30px;
    color: #172845;
    opacity: 0.87;
    font-size: 25px;
    font-family: Montserrat,sans-serif;
    font-weight: bold;
}
.row2-landing p{
    color: #000000DE;
    opacity: 1;
    line-height: 28px;
    font-size: 16px;
    padding-left: 140px;
    padding-right:350px;
    font-family: "Montserrat";
}

.row2-landing button{
    margin-left: 180px;
    
}
.bot-learn p{
    margin-left: 0;
}
.row2-landing button{
    margin-left: 140px;
    margin-top: 20px;
    background-color: #f2f6f9;
    border-radius: 3px;
    height: 35px;
    color:#C1303E ;
    font-weight: 600;
    margin-bottom: 40px;
    border: 2px solid #C1303E;
    font-family: "montserrat";
    font-size: 12pxs;
}

.bot-learn p{
    font-size: 10px;
    font-weight: bold;
    margin-top: 8px;
    font-family: Montserrat,sans-serif;
    color: #C1303E;
    opacity: 1;
    margin-left: 0;
    width: 80px;
    height: 80px;
   
    
}

.pic1{
    height: auto;
    width: 33%;
 
    background-image: url("/Artboard2.png");
     background-size: 100% 100%;
      background-repeat: no-repeat;
 
    
}
.pic1 img{
    width: 100%;
}

/* .pic1 img{
    
    width: 90%;
} */

.pic2{
    height: auto;
    width: 33%;
    
    background-image: url("/Artboard3.png");
    background-size: 100% 100%;
      background-repeat: no-repeat;
  
}
.pic2 img{
    width: 100%;
}
/* .pic2 img{
    width: 90%;
    
} */
.pic3{
    height: auto;
    width: 33%;
    
    background-image: url("/Artboard4.png");
    background-size: 100% 100%;
      background-repeat: no-repeat;
     
}
.pic3 img{
    width: 100%;
}
/* .pic3 img{
   
    width: 90%;
} */

.pictures{
    background-color: #f2f6f9;
width:auto;
    padding-bottom: 80px;
    padding-left: 120px;
    padding-right: 120px;
   
}
.col-sm-8 h4{
    margin-left:90px;
}
.col-sm-4 button{
    border: 2px solid white;
    background-color:#C1303E;
    border-radius: 8px;
}

.col-sm-3 button{
 border: 2px solid white;
    background-color:#C1303E;
    border-radius: 8px;
    height: 35px;
    width: auto;
    font-weight: bold;
    margin-left: 110px;
}
.fa-refresh{
    color: white;
}
.Myrow3 button:hover{
    background-color: red;
}
.test1{
    padding-left: 30px;
    padding-top: 60px;
}
.Myrow3 button{
    height: 40px;
    width: 150px;
    margin-top: 45px;
    margin-left: 20px;
    border-radius: 8px;
    border:2px solid white;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
}

.col-sm-3 button:hover{
    background: red;
}
#link-pics{
    text-decoration: none;
    color:white;
    font-weight: bold;  
  margin-left: 20px;
  margin-right: 20px;
}

#link-pics:hover{
color: grey;
}

.Myrow5 h4{
    font-size: 14px;
    margin-top: 20px;
 
 
  
    color: white;
    font-weight: lighter;
}
.Myrow5 a{
    color: white;
    text-decoration: underline !important;
    font-weight: bold;
      cursor: pointer;
}
.Myrow5 a:hover{
    color: blue !important;
}
#buttom-menu-drr{
    margin-left: 24px;
    font-size: 13px;
    font-weight:100;
    color: #38465f;
    font-weight: 400;
    
}
#buttom-menu-drr:hover{
    text-decoration: none;
    color: grey;
    
}
.support h4{
    margin-left: 40px;
   
}

.left{
    margin-right: auto;
}
.youth-lab{
    margin-bottom: 20px;
}
.youth-lab img{
     height: 80px;
    width: 200px;
    margin-left: 40px;
    margin-top: 5px;
}

.youth-lab h4{
font-size: 14px;
padding-left: 80px;
font-family: Montserrat,sans-serif;
} 

.DRR{
 
    padding: 0 !important;
}




.mobile-container {
  max-width: 576px;
  margin: auto;
  background-color: #555;
  height: auto;
  color: white;
  border-radius: 10px;
}

.topnav {
  overflow: hidden;
  background-color: #172845;
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  margin-left: 20px;
}



.topnav img{
    
    width: 70px;
  height: 60px;
   
   
}

#header{
    margin-left: 0px;
    padding-left: 0px;
    line-height: 1;
    font-weight: bold;
    font-size: 14px;
}
.topnav a.icon {
  background: #172845;
  display: block;
  position: absolute;
  color: white;
  right: 0;
  top: 0;
  margin-right: 10px;
  margin-top: 10px;
}
#topnav-nav-right{
    color: grey;
}
.toggle{
    color: white !important;
}
.active {
  background-color: #36455e;
 
}

.nav-link1{
    background-color: #36455e !important;
}
@media (min-width: 320px) and (max-width: 767px) { 
    .landing-image {
        padding: 0% 7%;
    }
    .row2-landing {
        padding-left: 30px;
        padding-top: 20px; 
        padding-right: 30px;  
    }
    .footer-banner-button {
        display: inline;
        
    }
    .footer-banner-wrapper {
        padding: 5% 5%;
    }
 
}
@media (min-width: 768px) and (max-width: 991px) {
    .Myrow1 h4 {
        padding-left: 67px;
    }
    .Myrow1 button {
        margin-left: 67px;
    }
    .row2-landing h4 {
        padding-left: 67px;
    }
    .row2-landing p {
        padding-left: 67px;
        padding-right: 67px;
    }
    .pictures {
        padding-left: 50px;
        padding-right: 75px;
    }
    .row2-landing button {
        margin-left: 67px;
        margin-bottom: 30px;
    }
}
@media (min-width: 992px) and (max-width: 1199px)  {
    .Myrow1 h4 {
        padding-left: 85px;
    }
    .Myrow1 button {
        margin-left: 85px;
    }
    .row2-landing h4 {
        padding-left: 85px;
    }
    .row2-landing p {
        padding-left: 85px;
        padding-right: 85px;
    }
    .pictures {
        padding-left: 68px;
        padding-right: 75px;
    }
    .row2-landing button {
        margin-left: 85px;
        margin-bottom: 30px;
    }
}
@media (min-width: 1200px) and (max-width: 1419px) {
    .Myrow1 h4 {
        padding-left: 8%;
    }
    .Myrow1 button {
        margin-left: 8%;
    }
    .row2-landing h4 {
        padding-left: 8%;
    }
    .row2-landing p {
        padding-left: 8%;
        padding-right: 8%;
    }
    .pictures {
        padding-left: 6.5%;
        padding-right: 7%;
    }
    .row2-landing button {
        margin-left: 8%;
        margin-bottom: 30px;
    }
}

.loginwrapper {
  margin-top: 40px;
  margin-bottom: 80px;
}

.form-signin {
  max-width: 400px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-size: 16px !important;
}
.form-signin .form-signin-heading {
  margin-bottom: 30px;
}
.btn-block{
  width: 100% !important;
  display: block !important;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  padding: 7px !important;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}

.form-signin button[type="submit"] {
  height: 36px !important;
  font-size: 16px !important;
  padding: 0;
  outline: none;
}

/*-----------------------------------------------
	submit button
-----------------------------------------------*/
button[type="submit"], .outline-none {
  outline: none !important;
}

/*==================================================
 * shadow-1
 * ===============================================*/
.shadow-1 {
  box-shadow: 0 15px 10px -10px rgba(31, 31, 31, 0.5);
}


/* author */
.author {
  float: left;
  font-family: "Maven Pro", sans-serif;
  font-size: 18px;
  margin: 15px 20px 30px;
}

body{
    overflow-x: hidden;
}
.container img{
    height: 70px;
    width: 70px;
}
.container h5{
    color: white;
    opacity: 0.87;
   
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
  min-height: 86vh;
  margin-left: -15rem;
  transition: margin .25s ease-out;
  
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
.nav-item button{
  margin-top: 7px;
  margin-left: 10px;
  background-color: #343a40;
  border: none;
  color: silver;
}
.nav-item button:hover{
  color: grey;
}
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
.table{
    width: auto !important;
    margin-top: 20px;
    margin-left: 0px;
}

.Exbtn{
    height: 40px;
    width: 100px;
}

.load p{

margin-top: 250px;
margin-left: 320px;
}

.form-control{
    background: transparent;
}
form {
 width: 320px;
 margin: 8% 0% !important;
}
form h2 {
    font-size: 25px;
    font-family: "montserrat";
    color: #172845;
    margin-bottom: 30px;
}
form > div {
 position: relative;
 overflow: hidden;
 margin-bottom: 10px;
}
form .btn{
    background: #172845;
    border-color: #172845;
    padding: 8px 15px;
    margin-top: 15px;
    font-family: "montserrat";
    border-radius: 3px;
    height: 35px;
    font-weight: 600;
    font-size: 12px;
}
form input, form textarea {
 width: 100%;
 border: 2px solid gray;
 background: none;
 position: relative;
 top: 0;
 left: 0;
 z-index: 1;
 padding: 8px 12px;
 outline: 0;
}
form input:valid, form textarea:valid {
 background: white;
}
form input:focus, form textarea:focus {
 border-color: #357EBD;
}
form input:focus + label, form textarea:focus + label {
 background: #357EBD;
 color: white;
 font-size: 70%;
 padding: 1px 6px;
 z-index: 2;
 text-transform: uppercase;
}
form label {
 transition: background 0.2s, color 0.2s, top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;
 position: absolute;
 color: #999;
 padding: 7px 6px;
 font-weight: normal;
}
form textarea {
 display: block;
 resize: vertical;
}
form.go-bottom input, form.go-bottom textarea {
 padding: 12px 12px 12px 12px;
}
form.go-bottom label {
 top: 0;
 bottom: 0;
 left: 0;
 width: 100%;
}
form.go-bottom input:focus, form.go-bottom textarea:focus {
 padding: 4px 6px 20px 6px;
}
form.go-bottom input:focus + label, form.go-bottom textarea:focus + label {
 top: 100%;
 margin-top: -16px;
}
form.go-right label {
 border-radius: 0 5px 5px 0;
 height: 100%;
 top: 0;
 right: 100%;
 width: 100%;
 margin-right: -100%;
}
form.go-right input:focus + label, form.go-right textarea:focus + label {
 right: 0;
 margin-right: 0;
 width: 40%;
 padding-top: 5px;
}
.form-control{
    background: transparent;
}
form {
 width: 320px;
 margin: 20px;
}
form > div {
 position: relative;
 overflow: hidden;
}
.btn{
    width: auto;
    height: 40px;
    border-radius: 12px;
}
form input, form textarea {
 width: 100%;
 border: 2px solid gray;
 background: none;
 position: relative;
 top: 0;
 left: 0;
 z-index: 1;
 padding: 8px 12px;
 outline: 0;
}
form input:valid, form textarea:valid {
 background: white;
}
form input:focus, form textarea:focus {
 border-color: #357EBD;
}
form input:focus + label, form textarea:focus + label {
 background: #357EBD;
 color: white;
 font-size: 70%;
 padding: 1px 6px;
 z-index: 2;
 text-transform: uppercase;
}
form label {
 transition: background 0.2s, color 0.2s, top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;
 position: absolute;
 color: #999;
 padding: 7px 6px;
 font-weight: normal;
}
form textarea {
 display: block;
 resize: vertical;
}
form.go-bottom input, form.go-bottom textarea {
 padding: 12px 12px 12px 12px;
}
form.go-bottom label {
 top: 0;
 bottom: 0;
 left: 0;
 width: 100%;
}
form.go-bottom input:focus, form.go-bottom textarea:focus {
 padding: 4px 6px 20px 6px;
}
form.go-bottom input:focus + label, form.go-bottom textarea:focus + label {
 top: 100%;
 margin-top: -16px;
}
form.go-right label {
 border-radius: 0 5px 5px 0;
 height: 100%;
 top: 0;
 right: 100%;
 width: 100%;
 margin-right: -100%;
}
form.go-right input:focus + label, form.go-right textarea:focus + label {
 right: 0;
 margin-right: 0;
 width: 40%;
 padding-top: 5px;
}
.query-form{
    border: 1px solid black;
}

.query-data{
    padding-left: 0px !important ;
}

.sidebar{
    padding-right: 0px !important;
}
/* blog listing */
.micro-container-guideliness {
  padding-right: 0px !important;
  padding-left: 0px !important;
  background-image: url(/MicrosoftTeams-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.blog-box {
  margin-top: 10%;
}
.blog-row {
    margin-bottom: 30px;
    padding: 0% 7%;
  }
  .blog-list {
  padding: 40px 0px;
  background-color: #F2F6F9;
  }
  .blog-box img {
  width: 340px ;
  height: 259px ;
  border-radius: 3px;
  object-fit: cover;
  }
  .blog-tag {
    margin-top: 10px;
  }
  .blog-tag p{
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #676767;
    margin-bottom: 0px;
  }
  /* .blog-list-content{
    height: 158px;
  } */
  .blog-list-content h1{
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 29px;
  color: #35455E;
  margin-top: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 330px;
  }
  .blog-list-content p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  color: #676767;
  margin-top: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  width: 330px;
  }
   a {
    text-decoration: none;
  }
  .blog-box button {
    background: #fff;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #35455E;
    border-color: #C1303E;
  }
  .blog-box   .fa{
    color: #C1303E;
    opacity: 1;
    margin-left: 5px;
    font-size: 12px;
  }
  .blog-box button a{
    color: #35455E;
    background-color: transparent;
  }
   a:hover{
    color: #C1303E;
    text-decoration: none;
   
  } 
  /* .blog-box .btn:hover {
      color: #fff;
      background-color: #C1303E;
      border-color: #C1303E;
  } */
  /* .blog-box button  .fa:hover{
    color: #fff;
    opacity: 1;
  } */
  .readmore-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #C1303E;
    text-decoration: none;
    font-family: "montserrat";
  }
  .readmore-button a {
    text-decoration: none;
  }
  @media (min-width: 320px) and (max-width: 767px)  {
    /* .blog-box img {
      width: 330px !important;
  } */
  .blog-recent-article-content p {
    text-align: left;
    text-align: initial;
  }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .blog-box img {
      width: 200px;
      height: 180px;
    }
    .blog-list-content h1 {
      width: 200px;
    }
    .blog-list-content p {
      width: 200px;
    }
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    .blog-recent-article-content p {
      text-align: left;
      text-align: initial;
    }
    .blog-box img {
      width: 265px;
    }
    .blog-list-content h1 {
      width: 265px;
    }
    .blog-list-content p {
      width: 265px;
    }

  }
