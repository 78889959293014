body{
    overflow-x: hidden;
}
.container img{
    height: 70px;
    width: 70px;
}
.container h5{
    color: white;
    opacity: 0.87;
   
}

/*!
 * Start Bootstrap - Simple Sidebar (https://startbootstrap.com/templates/simple-sidebar)
 * Copyright 2013-2020 Start Bootstrap
 * Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-simple-sidebar/blob/master/LICENSE)
 */

 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
  min-height: 86vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}
.nav-item button{
  margin-top: 7px;
  margin-left: 10px;
  background-color: #343a40;
  border: none;
  color: silver;
}
.nav-item button:hover{
  color: grey;
}
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
.table{
    width: auto !important;
    margin-top: 20px;
    margin-left: 0px;
}

.Exbtn{
    height: 40px;
    width: 100px;
}

.load p{

margin-top: 250px;
margin-left: 320px;
}
